import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { c } from 'ttag';

import {
    Alert,
    AuthStep,
    AuthenticationStore,
    Button,
    Divider,
    useAuthentication,
    useLocalState,
} from '@freelancelabs/components';
import { Magic } from '@freelancelabs/icons';

import PublicLayout from '../app/content/PublicLayout';
import LoginForm from '../app/login/LoginForm';
import SetPasswordForm from '../app/login/SetPasswordForm';
import { AlertType, PublicRoutes, alertMessages, defaultUsernameKey } from '../constants';

const LoginPage = () => {
    const location = useLocation();
    const { completeNewPassword } = useAuthentication() as AuthenticationStore;
    const [username, setUsername] = useLocalState('', defaultUsernameKey);
    const [step, setStep] = useState(AuthStep.LOGIN);
    const alert: AlertType = location.state?.withAlert;

    return (
        <PublicLayout>
            {alert && (
                <Alert
                    type="danger"
                    title={alertMessages()[alert].title}
                    message={alertMessages()[alert].message}
                    className="mb-4"
                />
            )}
            {step === AuthStep.LOGIN && (
                <>
                    <Button size="large" to={PublicRoutes.LOGIN_PASSWORD_LESS}>
                        <Magic />
                        {c('Password less link').t`Login without password`}
                    </Button>
                    <Divider className="my-8">{c('Divider').t`or`}</Divider>
                    <LoginForm
                        defaultUsername={username}
                        onSubmit={(data) => {
                            setUsername(data.payload.username);
                            if (data.challenge === 'NEW_PASSWORD_REQUIRED') {
                                setStep(AuthStep.NEW_PASSWORD);
                            }
                        }}
                    />
                </>
            )}
            {step === AuthStep.NEW_PASSWORD && (
                <>
                    <h1 className="text-2xl font-semibold text-text-norm pb-2">
                        {c('New password required title').t`Create a new password`}
                    </h1>
                    <span className="text-text-norm pb-6">
                        {c('New password required help').t`For security reasons you must set a new password.`}
                    </span>
                    <SetPasswordForm
                        username={username}
                        onSubmit={(newPassword) => {
                            return completeNewPassword(newPassword);
                        }}
                    />
                </>
            )}
        </PublicLayout>
    );
};
export default LoginPage;
