import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { c } from 'ttag';

import {
    AuthenticationStore,
    Button,
    InputField,
    useAuthentication,
    useFormErrors,
    useLoading,
    useNotifications,
} from '@freelancelabs/components';
import { emailValidator, requiredValidator } from '@freelancelabs/shared';

import { PublicRoutes } from '../../constants';

interface Props {
    defaultEmail?: string;
}

const ResetPasswordForm = ({ defaultEmail }: Props) => {
    const { forgotPassword } = useAuthentication() as AuthenticationStore;
    const { validator, onFormSubmit } = useFormErrors();
    const [submitting, withSubmitting] = useLoading();
    const [username, setUsername] = useState(defaultEmail || '');
    const { createNotification } = useNotifications();
    const navigate = useNavigate();

    const handleSubmit = () => {
        if (submitting || !onFormSubmit()) {
            return;
        }
        const run = async () => {
            await forgotPassword(username);
            return navigate(PublicRoutes.EMAIL_SENT, { state: { username } });
        };

        withSubmitting(run()).catch((e) => {
            const code = e.code;
            switch (code) {
                case 'LimitExceededException':
                    createNotification({
                        type: 'danger',
                        text: c('Reset Password Error').t`Attempt limit exceeded`,
                    });
                    break;
                default:
                    createNotification({
                        type: 'danger',
                        text: c('Reset Password Error').t`Unknown error`,
                    });
            }
        });
    };

    return (
        <>
            <form
                name="registerForm"
                className="flex flex-col"
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit();
                }}
                method="post"
            >
                <InputField
                    label={c('Info').t`Reset email`}
                    id="username"
                    error={validator([requiredValidator(username), emailValidator(username)])}
                    disableChange={submitting}
                    autoComplete="username"
                    type="email"
                    value={username}
                    onValue={setUsername}
                />
                <Button size="large" loading={submitting}>{c('Action').t`Send`}</Button>
            </form>
            <hr className="my-6" />
            <div className="text-center text-sm font-medium">
                <Link className="link" to={PublicRoutes.LOGIN}>
                    {c('Link').t`Back to sign in`}
                </Link>
            </div>
        </>
    );
};

export default ResetPasswordForm;
