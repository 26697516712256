import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface Props {
    isAllowed: boolean;
    redirectPath: string;
}

const ProtectedRoute = ({ isAllowed, redirectPath }: Props) => {
    return isAllowed ? <Outlet /> : <Navigate to={redirectPath} replace />;
};

export default ProtectedRoute;
