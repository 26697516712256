import React, { useEffect } from 'react';

import { LoaderPage, useAuthentication, useI18n, useLocalState } from '@freelancelabs/components';

import { defaultUsernameKey, redirectAfterLoginKey } from '../constants';
import Pages from '../pages';

const Setup = () => {
    const { isInitialized: isInitializedAuth } = useAuthentication();
    const { isInitialized: isInitializedI18n } = useI18n();

    const searchParams = new URLSearchParams(location.search);
    const defaultUsername = searchParams.get('username');
    const redirectAfterLogin = searchParams.get('redirect-after-login');
    const [, setUsername] = useLocalState(defaultUsername || '', defaultUsernameKey);
    const [, setRedirectAfterLogin] = useLocalState(redirectAfterLogin || '', redirectAfterLoginKey);

    useEffect(() => {
        if (defaultUsername) {
            setUsername(defaultUsername);
        }
        if (redirectAfterLogin) {
            setRedirectAfterLogin(redirectAfterLogin);
        }
    }, []);

    if (!isInitializedAuth || !isInitializedI18n) {
        return <LoaderPage />;
    }

    return <Pages />;
};

export default Setup;
