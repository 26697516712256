import {
    AuthenticationProvider,
    ConsentProvider,
    I18nProvider,
    MonitoringErrorBoundary,
    NotificationsChildren,
    NotificationsProvider,
} from '@freelancelabs/components';
import { initLocales } from '@freelancelabs/shared';

import Setup from './Setup';

// @ts-ignore
initLocales(import.meta.glob('../../locales/*.json'));

const App = () => {
    return (
        <MonitoringErrorBoundary>
            <I18nProvider>
                <NotificationsProvider>
                    <AuthenticationProvider
                        awsUserPoolsId={import.meta.env.PUBLIC_AWS_USER_POOLS_ID}
                        awsUserPoolsWebClientId={import.meta.env.PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID}
                        awsCognitoRegion={import.meta.env.PUBLIC_AWS_COGNITO_REGION}
                    >
                        <ConsentProvider>
                            <NotificationsChildren />
                            <Setup />
                        </ConsentProvider>
                    </AuthenticationProvider>
                </NotificationsProvider>
            </I18nProvider>
        </MonitoringErrorBoundary>
    );
};

export default App;
