import * as React from 'react';

import { Menu } from '@headlessui/react';

import { ButtonCategory, ButtonSize } from '../../atoms/button';
import { usePopperAnchor } from '../../atoms/popper';
import DropdownMenu from './DropdownMenu';
import { DropdownMenuButton } from './index';

export interface DropdownProps {
    content?: React.ReactNode;
    children: React.ReactNode;
    buttonSize?: ButtonSize;
    buttonCategory?: ButtonCategory;
}

function Dropdown({ children, content, buttonSize, buttonCategory }: DropdownProps) {
    const { anchorRef } = usePopperAnchor<HTMLButtonElement>();
    return (
        <Menu>
            {({ open }) => (
                <>
                    <Menu.Button tabIndex={-1} as="div" ref={anchorRef} className="dropdown-button">
                        <DropdownMenuButton
                            open={open}
                            children={content}
                            size={buttonSize}
                            category={buttonCategory}
                        />
                    </Menu.Button>
                    <DropdownMenu anchorRef={anchorRef} isOpen={open}>
                        {children}
                    </DropdownMenu>
                </>
            )}
        </Menu>
    );
}

export default Dropdown;
