import * as React from 'react';
import { Ref, SVGProps, forwardRef } from 'react';

type IconSize = 6 | 12 | 14 | 16 | 20 | 24 | 48 | 64 | 96 | 126;
interface Props extends SVGProps<SVGSVGElement> {
    /** If specified, renders an inline title element */
    title?: string;
    /** The size of the icon */
    size?: IconSize;
    /** How many degrees the icon should be rotated */
    rotate?: number;
    className?: string;
    alt?: string;
}
const SvgEyeOutline = ({ size = 16, rotate, title, className, ...props }: Props, ref: Ref<SVGSVGElement>) => {
    const titleId = title;
    const style = {
        height: size.toString(10).concat('px'),
        width: size.toString(10).concat('px'),
        ...(rotate && {
            transform: 'rotate('.concat(rotate.toString(10)).concat('deg)'),
        }),
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            focusable="false"
            role="img"
            style={style}
            className={className}
            ref={ref}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
            />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
    );
};
const ForwardRef = forwardRef(SvgEyeOutline);
export default ForwardRef;
