import React, { useEffect, useState } from 'react';

import { c } from 'ttag';

import { CheckCircleSolid, ErrorCircleSolid } from '@freelancelabs/icons';
import {
    defaultMinPasswordLength,
    passwordCapitalizeValidator,
    passwordLengthValidator,
    passwordLowerValidator,
    passwordNumberValidator,
    passwordSpecialCharValidator,
} from '@freelancelabs/shared';
import { clsx } from '@freelancelabs/utils';

interface Props {
    password?: string;
}

const PasswordCheck = ({ password = '' }: Props) => {
    const [checkLength, setCheckLength] = useState(false);
    const [checkCapitalize, setCheckCapitalize] = useState(false);
    const [checkLower, setCheckLower] = useState(false);
    const [checkSpecialChar, setCheckSpecialChar] = useState(false);
    const [checkNumber, setCheckNumber] = useState(false);

    useEffect(() => {
        setCheckCapitalize(!passwordCapitalizeValidator(password));
        setCheckLength(!passwordLengthValidator(password));
        setCheckSpecialChar(!passwordSpecialCharValidator(password));
        setCheckLower(!passwordLowerValidator(password));
        setCheckNumber(!passwordNumberValidator(password));
    }, [password]);

    return (
        <div className="flex flex-col text-xs font-medium">
            <span className="text-text-weak pt-2">
                {c('Password').t`To be valid, your password must contains at least:`}
            </span>
            <div className="flex flex-wrap gap-2 pt-1">
                <div className={clsx('flex items-center gap-1', checkLength ? 'text-success' : 'text-text-weak')}>
                    {checkLength ? <CheckCircleSolid /> : <ErrorCircleSolid />}
                    <span>{c('Password').jt`${defaultMinPasswordLength} chars`}</span>
                </div>
                <div className={clsx('flex items-center gap-1', checkSpecialChar ? 'text-success' : 'text-text-weak')}>
                    {checkSpecialChar ? <CheckCircleSolid /> : <ErrorCircleSolid />}
                    <span>{c('Password').t`1 special char`}</span>
                </div>
                <div className={clsx('flex items-center gap-1', checkNumber ? 'text-success' : 'text-text-weak')}>
                    {checkNumber ? <CheckCircleSolid /> : <ErrorCircleSolid />}
                    <span>{c('Password').t`1 number`}</span>
                </div>
                <div className={clsx('flex items-center gap-1', checkCapitalize ? 'text-success' : 'text-text-weak')}>
                    {checkCapitalize ? <CheckCircleSolid /> : <ErrorCircleSolid />}
                    <span>{c('Password').t`1 uppercase`}</span>
                </div>
                <div className={clsx('flex items-center gap-1', checkLower ? 'text-success' : 'text-text-weak')}>
                    {checkLower ? <CheckCircleSolid /> : <ErrorCircleSolid />}
                    <span>{c('Password').t`1 lowercase`}</span>
                </div>
            </div>
        </div>
    );
};

export default PasswordCheck;
