import * as React from 'react';

import { CheckCircleSolid, ErrorCircleSolid, InfoCircleSolid, WarningSolid } from '@freelancelabs/icons';
import { clsx } from '@freelancelabs/utils';

export interface AlertProps {
    title?: string;
    message?: string;
    type?: 'info' | 'danger' | 'warning' | 'success';
    className?: string;
    children?: React.ReactNode;
}

const Alert = ({ type = 'info', title, message, className, children }: AlertProps) => {
    const getIcon = () => {
        switch (type) {
            case 'success':
                return <CheckCircleSolid className="alert-block-icon" size={24} />;
            case 'warning':
                return <WarningSolid className="alert-block-icon" size={24} />;
            case 'danger':
                return <ErrorCircleSolid className="alert-block-icon" size={24} />;
            default:
                return <InfoCircleSolid className="alert-block-icon" size={24} />;
        }
    };
    return (
        <div className={clsx('alert-block-container', `alert-block-${type}`, className)}>
            <div className="alert-block">
                {getIcon()}
                <div className="alert-block-content">
                    <span className="alert-block-title">{title}</span>
                    <span className="alert-block-message">{message}</span>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Alert;
