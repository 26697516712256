export type LoginInput = {
    email: string;
    password: string;
};

export type LoginCheckInput = {
    email: string;
    code: string;
};

export type ForgotPasswordSubmitInput = {
    email: string;
    code: string;
    newPassword: string;
};

export type ChallengeName = 'CUSTOM_CHALLENGE' | 'NEW_PASSWORD_REQUIRED';

export interface AuthenticationStore {
    isInitialized: boolean;
    hasSession: boolean;
    logout: () => Promise<void>;
    getAuthorizationToken: () => Promise<string | null>;
    login: (args: LoginInput) => Promise<ChallengeName | undefined>;
    passwordLessLogin: (email: string) => Promise<void>;
    passwordLessLoginCheck: (args: LoginCheckInput) => Promise<void>;
    completeNewPassword: (password: string) => Promise<void>;
    forgotPassword: (username: string) => Promise<void>;
    forgotPasswordSubmit: (args: ForgotPasswordSubmitInput) => Promise<string>;
}

export enum AuthStep {
    LOGIN,
    NEW_PASSWORD,
    DONE,
}
