import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { c } from 'ttag';

import {
    AuthenticationStore,
    Button,
    ChallengeName,
    InputField,
    PasswordInput,
    useAuthentication,
    useFormErrors,
    useLoading,
    useNotifications,
} from '@freelancelabs/components';
import { requiredValidator } from '@freelancelabs/shared';

interface Props {
    onSubmit: (data: {
        challenge: ChallengeName | undefined;
        payload: {
            username: string;
            password: string;
        };
    }) => void;
    defaultUsername?: string;
}

const LoginForm = ({ defaultUsername = '', onSubmit }: Props) => {
    const { login } = useAuthentication() as AuthenticationStore;
    const { validator, onFormSubmit } = useFormErrors();
    const [submitting, withSubmitting] = useLoading();
    const [username, setUsername] = useState(defaultUsername || '');
    const [password, setPassword] = useState('');

    const { createNotification } = useNotifications();

    const handleSubmit = () => {
        if (submitting || !onFormSubmit()) {
            return;
        }
        const run = async () => {
            const challenge = await login({ email: username, password });
            return onSubmit({
                challenge,
                payload: {
                    username,
                    password,
                },
            });
        };

        withSubmitting(run()).catch((e) => {
            const message = e.message;
            switch (message) {
                case 'Incorrect username or password.':
                    createNotification({
                        type: 'danger',
                        text: c('Login Error').t`Incorrect username or password.`,
                    });
                    break;
                case 'Password attempts exceeded':
                    createNotification({
                        type: 'danger',
                        text: c('Login Error').t`Password attempts exceeded`,
                    });
                    break;
                default:
                    createNotification({
                        type: 'danger',
                        text: c('Login Error').t`Unknown error`,
                    });
            }
        });
    };

    return (
        <form
            name="loginForm"
            className="flex flex-col"
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
            }}
            method="post"
        >
            <InputField
                label={c('Info').t`Email`}
                id="username"
                type="email"
                error={validator([requiredValidator(username)])}
                disableChange={submitting}
                autoComplete="username"
                value={username}
                onValue={setUsername}
            />
            <InputField
                label={c('Info').t`Password`}
                id="password"
                as={PasswordInput}
                error={validator([requiredValidator(password)])}
                disableChange={submitting}
                autoComplete="current-password"
                value={password}
                onValue={setPassword}
            />
            <div className="flex justify-end">
                <Link to="/reset-password" className="link text-sm font-medium">
                    {c('Link').t`Reset password`}
                </Link>
            </div>
            <Button size="large" category="weak" type="submit" fullWidth loading={submitting} className="mt-4">
                {c('Action').t`Login`}
            </Button>
        </form>
    );
};

export default LoginForm;
