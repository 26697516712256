﻿export default () => {
    return `
# CONDITIONS GENERALES D'UTILISATION

### PÉRIMÈTRE GÉOGRAPHIQUE

La plateforme FREELANCE.COM s'adresse aux professionnels du monde entier, à l'exclusion des pays soumis à un embargo économique par une résolution de l'O.N.U. L'UE ou l'Etat français

### MENTIONS LEGALES

#### LA PLATEFORME FREELANCE.COM EST ÉDITÉE PAR :

La société FREELANCE.COM SA au capital de 4 440 693,52 € dont le siège social est établi au 1, Parvis de la Défense, 92044 Paris la Défense cedex – France, immatriculée au RCS n° 384.174.348, représentée par Sylvestre BLAVET, Président Directeur Général.

#### LA PLATEFORME FREELANCE.COM EST HÉBERGÉE PAR :

La société OVH SAS au capital de 5 000 000 € dont le siège social est établi au 2 rue Kellermann – 59100 Roubaix – France, immatriculée au RCS Roubaix – Tourcoing n°424.761.419.00045
Code APE 6202A

#### LEXIQUE

- ACCORD : exprime le lien contractuel ainsi que les conditions générales exprimées dans les présentes.
- CLIENT : DONNEURS D'ORDRES, FOURNISSEURS, salariés portés inscrits sur la PLATEFORME.
- DONNEUR D'ORDRES : le donneur d'ordres est une personne morale ou une personne physique ayant la qualité d'entrepreneur individuel, ayant sollicité des ressources sur la plateforme ou proposé une offre de mission.
- FOURNISSEUR : une personne morale ou une personne physique ayant la qualité d'entrepreneur individuel, à la recherche de missions et ayant vocation à répondre en qualité d'indépendant ou de salarié porté à une commande de prestations ou d'un marché de travaux ou de fournitures.
- Salariés Portés : Personnes ayant prospecté un client et négocié avec lui les éléments essentiels d'une prestation de service.
- Consultants : Les candidats ayant prospecté et négocié un contrat avec leurs clients et qui s'inscrivent sur la Plateforme pour solliciter un contrat de travail en portage salarial.
- MEMBRE : le terme « membre » désigne un utilisateur identifié sur la plateforme.
- VISITEUR : toute personne physique ou morale n'ayant pas la qualité de CLIENT, visitant le site.
- DIFFUSEUR : la plateforme FREELANCE.COM.
- MARCHÉ : les marchés objets de la prestation FREELANCE.COM, sont constitués par tous les pays du globe à l'exception des pays frappés d'embargo économique par une résolution de l'O.N.U.
- PLATEFORME : la plateforme FREELANCE.COM désigne la plateforme internet qui offre les prestations objet des présentes CGU.
- FREELANCE.COM : désigne la société FREELANCE.COM et/ou ses filiales ou l'une de ses filiales précisées en Annexe 1.
- UTILISATEUR : utilisateur est un terme générique désignant notamment les trois catégories sus indiquées « FOURNISSEURS, et DONNEURS D'ORDRE », ayant accès à la PLATEFORME, Salariés Portés.
- DOCUMENTS OBLIGATOIRES : désignent d'une part les documents sous forme de CV, retraçant les expériences et savoir faire des FOURNISSEURS, et d'autre part toute donnée économique inhérente à l'activité de l'UTILISATEUR, tous documents légaux exigés par la loi et la règlementation, dans les conditions exigées par la plateforme FREELANCE.COM et les présentes CGU.
- DROITS À TELECHARGEMENTS (DAT) : la plateforme FREELANCE.COM met à disposition de ses CLIENTS des fichiers disponibles au téléchargement selon les conditions publiées sur la PLATEFORME et les présentes CGU.
- INFORMATIONS OU DONNÉES : tout document publié sur la plateforme par le CLIENT.
- IDENTIFIANT : le terme « IDENTIFIANT » recouvre les informations nécessaires à l'identification d'un utilisateur sur la plateforme pour accéder aux zones réservées aux membres.
- MOT DE PASSE : le « MOT DE PASSE » est une information confidentielle, dont l'utilisateur doit garder le secret, lui permettant, utilisée conjointement avec son identifiant, de prouver son identité.
- PORTAGE SALARIAL : désigne l'activité de portage salarial telle que régie par l'ordonnance du 2 avril 2015.
- TALENT ACQUISITION : désigne le service de sourcing de FREELANCE.COM, permettant aux indépendants d'être sélectionner pour une mission proposée par un DONNEUR D'ORDRES.

### OBJET ET CHAMPS D'APPLICATION

La PLATEFORME FREELANCE.COM s'adresse à toute personne physique ou morale ayant l'intention de conclure dans le futur un contrat de prestation de service avec la société Freelance.com ou l'une de ses filiales pour la réalisation de prestations pour le compte d'un Prestataire ou un Donneur d'ordre.

La PLATEFORME permet à cette fin aux FOURNISSEURS et aux Consultants de transmettre, si nécessaire, les conditions exprimées dans les présentes CGU, leurs CVs et toute information économique et commerciale permettant d'exprimer leur parcours professionnel à la PLATEFORME. La PLATEFORME permet par ailleurs dans les conditions exprimées dans les présentes CGU, aux DONNEURS D'ORDRES de transmettre des offres de missions afin de faire des demandes à FREELANCE.COM via la PLATEFORME des ressources compétentes.

Le Consultant garantit le respect strict des règles régissant le portage salarial, et déclare expressément que toute initiative quelle qu'elle soit à l'égard du client ou à l'égard des décisions qu'il prend à ce titre relève da sa propre initiative et de son libre choix.

Le Consultant ayant prospecté un client et négocié avec lui les éléments essentiels du contrat de prestation de services en portage salarial, peut simuler le salaire en résultant. La simulation de salaire ne peut engager de quelque manière que ce soit FREELANCE.COM, elle est réalisée à titre informatif, seul le contrat de prestation de services en portage salarial et le contrat de travail en portage salarial pourront engager les parties dans les conditions des dispositions légales régissant le portage salarial.

A ce titre FREELANCE.COM n'est pas soumise à l'obligation de conclure le contrat de prestation de services en portage salarial ou le contrat de travail en portage salarial. FREELANCE.COM se réserve le droit de refuser de conclure les contrats précités sans avoir à motiver sa décision.

L'UTILISATEUR peut souscrire dans les conditions indiquées sur la PLATEFORME ou tout autre support publié par FREELANCE.COM, à des événements organisés par cette dernière, tels que des réunions d'information sur le portage salarial, ateliers thématiques ou autres événements publiés par la PLATEFORME.

Il est parfaitement entendu enfin que l'UTILISATEUR reste totalement libre de ses choix en la matière, de consulter de prospecter et de négocier ou non les offres.

De convention expresse entre les parties, aucune relation contractuelle dans le cadre d'une mission ne sera établie en l'absence de contrat et de commande dûment conclus avec FREELANCE.COM.

La PLATEFORME permet à ce titre aux UTILISATEURS de contacter chaque société du groupe FREELANCE.COM selon leurs besoins en termes d'activité, de missions ou de ressources adaptées.

Tout contrat conclu par un FOURNISSEUR avec un cocontractant concernant les activités proposées par la PLATEFORME doit respecter les dispositions légales et règlementaires régissant la matière, notamment dans le domaine du portage salarial qui procède de la prospection et la négociation entre le FOURNISSEUR et le DONNEUR D'ORDRES des éléments essentiels de la prestation.

Les CGU s'appliquent à toute utilisation de la PLATEFORME.

Les présentes CGU rentrent en vigueur à compter de leur validation par l'UTILISATEUR. Cette version annule et remplace les versions ou contrats antérieurs.

L'UTILISATEUR est invité à lire attentivement les CGU, qui sont accessibles par un lien hypertexte depuis la PLATEFORME. Il est conseillé à l'UTILISATEUR de les télécharger et/ou de les imprimer et d'en conserver une copie. Il est également conseillé à l'UTILISATEUR de lire les CGU à chaque visite de la PLATEFORME, dès lors que celles-ci peuvent être modifiées à tout moment.

La modification des CGU sera notifiée aux UTILISATEURS par une fenêtre à cet effet apparaissant sur la PLATEFORME. La modification des CGU sera réputée tacitement acceptée par l'UTILISATEUR, si ce dernier poursuit sa connexion à la PLATEFORME sans contestation expresse.

Chaque activité du groupe FREELANCE.COM implique une procédure de contractualisation telle que prescrite par la PLATEFORME dans la rubrique y afférente.

Toute solidarité contractuelle entre FREELANCE.COM et l'une de ses filiales ou entre ses filiales est strictement exclue.

### UTILISATION DE LA PLATEFORME

L'utilisation de la PLATEFORME est réservée aux UTILISATEURS ayant préalablement pris connaissance et accepté les CGU par un « clic ».

Les UTILISATEURS s'engagent à ce que le contenu des messages et documents envoyés ne soient pas contraires à la législation en vigueur et ne soient pas constitutifs de faux documents. Ils s'engagent notamment à ne pas utiliser la PLATEFORME aux fins de commettre une ou des infractions pénales ou toute autre infraction légale.

Tout document ou information mis en ligne sur la PLATEFORME, en infraction à ces dispositions entrainera immédiatement et de plein droit la résiliation des présentes et l'exclusion de l'UTILISATEUR de la PLATEFORME, sans préjudice d'une action judiciaire en réparation du dommage causé à FREELANCE.COM, et / ou à l'un quelconque de ses CLIENTS.

L'accès à certaines rubriques de la PLATEFORME nécessite l'utilisation d'un identifiant et d'un mot de passe. Le mot de passe, choisi par l'UTILISATEUR lui est personnel et confidentiel.

L'UTILISATEUR est seul responsable de l'utilisation qui peut être faite de son identifiant et de son mot de passe et s'engage à conserver secret son mot de passe et à ne pas le divulguer à qui que ce soit et sous quelque forme que ce soit. L'utilisation de son identifiant et de son mot de passe à travers internet se fait aux risques assumés et consentis par l'UTILISATEUR. Il lui appartient de prendre toutes les dispositions nécessaires permettant de protéger ces données contre toute atteinte.

Le processus de contractualisation avec les FOURNISSEURS est établi par le service CONNECTEED accessible par un lien. Il implique en amont la conclusion du contrat cadre (CCFRA), la conclusion d'une commande et l'envoi de tous documents contractuel et légal (notamment les documents exigés par les articles L 8222-1, D8222-5 et D8222-7). Pendant l'exécution du contrat notamment par la transmission des comptes rendus d'activité ou les près verbaux de livraisons ainsi que les factures.

### ACCÈS AU SERVICE

Le service est accessible dans les conditions décrites dans les présentes à tous UTILISATEURS disposant d'un accès internet, après acceptation expresse des présentes Conditions Générales d'Utilisation.

Certaines sections de la plateforme sont réservées aux UTILISATEURS disposant d'un identifiant et d'un mot de passe, les FOURNISSEURS et DONNEURS D'ORDRES disposent de sections indépendantes.

FREELANCE.COM se réserve le droit de refuser l'accès au Service, unilatéralement et sans notification préalable, à tout UTILISATEUR ne respectant pas les présentes Conditions Générales d'Utilisation.

Tous les coûts afférents aux moyens d'accès au Service, sont exclusivement à la charge de l'UTILISATEUR. Il est seul responsable du bon fonctionnement de son équipement informatique ainsi que de son accès à internet.

FREELANCE.COM met en œuvre tous les moyens raisonnables à sa disposition pour assurer un accès de qualité au Service, mais n'est tenu à aucune obligation d'y parvenir.

FREELANCE.COM ne peut, en outre, être tenue responsable de tout dysfonctionnement du réseau ou des serveurs ou de tout autre événement échappant au contrôle raisonnable, qui empêcherait ou dégraderait l'accès au Service.

FREELANCE.COM se réserve la possibilité d'interrompre, de suspendre momentanément ou de modifier sans préavis l'accès à tout ou partie du Service, afin d'en assurer la maintenance, ou pour toute autre raison, sans que l'interruption n'ouvre droit à aucune obligation ni indemnisation.

### OBLIGATIONS DE L'UTILISATEUR

#### Obligation de pré-mise en ligne

Les UTILISATEURS sont seuls responsables de la fidélité et de l'authenticité des DOCUMENTS OBLIGATOIRES transmis à FREELANCE.COM, il est par ailleurs responsable du traitement de leur diffusion, à ce titre le DIFFUSEUR agit exclusivement sur instructions de l'UTILISATEUR.

L'obligation de l'UTILISATEUR est irrévocablement à ce titre de RESULTAT.

L'UTILISATEUR garantira l'authenticité et la conformité des documents à la législation Française régissant la matière, en vertu de quoi l'UTILISATEUR informera sous 24 heures à FREELANCE.COM d'une éventuelle incompatibilité.

L'UTILISATEUR s'engage à respecter la procédure décrite ci-après :

- Accepter sans aucune réserve ni contradiction les présentes conditions générales, et s'inscrire en ligne.
- Télécharger sur la plateforme FREELANCE.COM un bordereau électronique d'envoi en cas de nécessité, et le remplir sans aucune omission et rature.
- Réunir les pièces et documents nécessaires exigés dans les présentes CGU.

L'UTILISATEUR s'engage sous 48 heures, à adresser à FREELANCE.COM, de nouveaux documents en cas de non-conformité.

Tout manquement à cette obligation entrainera la suspension de l'engagement et sa résiliation unilatérale et de droit par FREELANCE.COM.

#### OBLIGATIONS DE MISE EN LIGNE

Le dossier de l'UTILISATEUR exige pour sa mise en ligne le respect au minima des conditions suivantes :

- Le dossier doit être complet : toutes les pièces exigées par la PLATEFORME doivent exister.
- Les CV des FOURNISSEURS doivent contenir 90% d'informations, dans les conditions exigées par les présentes CGU.
- Le dossier doit être accompagné le cas échéant de son règlement.

Le dossier fera l'objet d'un contrôle de conformité, de régularité et de fidélité aux prescriptions des présentes CGU et de celles de la PLATEFORME FREELANCE.COM.

FREELANCE.COM se réserve le droit discrétionnaire d'écarter toute mise en ligne d'un dossier en cas de :

- Dossier incomplet
- Dossier dont les pièces ne répondent pas ou répondent partiellement au contrôle qualitatif et quantitatif
- Absence de règlement le cas échéant.

Dans ce cas, un mail ou un courrier serait adressé à l'UTILISATEUR, afin de lui permettre de régulariser le dossier.

Sans réponse de sa part, ou en l'absence de régularisation dans le mois suivant la demande de régularisation de la part du DIFFUSEUR, et après mise en demeure, le dossier sera considéré comme définitivement non conforme, et non avenu, en conséquence la mise en ligne ne sera pas diligentée, et la somme éventuellement versée, restera acquise au DIFFUSEUR sans que l'UTILISATEUR ne puisse y prétendre pour quelques motifs qu'ils soient.

### OBLIGATIONS DU DONNEUR D'ORDRES

- Le DONNEUR D'ORDRE s'engage de s'abstenir de toute ingérence à l'égard de FREELANCE.COM.
- LE DONNEUR D'ORDRE s'engage à un usage loyal du site, et notamment à respecter toute obligation conditionnant la responsabilité de FREELANCE.COM.
- Afin de faire valoir ses droits, l'UTILISATEUR devra, sous peine de déchéance de toute action s'y rapportant, informer FREELANCE.COM, par écrit, de l'existence des vices dans un délai maximum d'un mois à compter de leurs découvertes sous peine de forclusion.
- LE DONNEUR D'ORDRE s'engage à informer en temps utile FREELANCE.COM de toute requête, de sorte que cette dernière soit en mesure d'y répondre rigoureusement et en temps utile sous peine de déchéance.

Faute de quoi toute responsabilité de FREELANCE.COM en cas de manquement sera écartée.

En tout état de cause le DONNEUR D'ORDRES s'engage à respecter les démarches d'inscription décrites sur la PLATEFORME.

La non-conformité par le DONNEUR D'ORDRE, à l'une des dispositions quelles qu'elles soient, décrites par les présentes CGU, la PLATEFORME et notamment celles décrites, entraine de facto et de droit la résiliation immédiate des présentes conditions générales sans préjudice des poursuites en dommages et intérêts.

Le DONNEUR D'ORDRES s'engage en outre expressément :

- À respecter scrupuleusement toutes les prescriptions des présentes CGU, ainsi que celles prescrites sur la PLATEFORME FREELANCE.COM.
- À ne pas débaucher, à ne pas faire débaucher ou à tenter de débaucher ou de faire débaucher, directement ou indirectement, un ou plusieurs employés des entreprises tiers notamment FOURNISSEURS ou de contacter directement un FOURNISSEUR sur la PLATEFORME sans l'accord exprès de FREELANCE.COM. Cette obligation perdurera pendant une durée de vingt-quatre (24) mois après la terminaison des présentes Conditions Générales pour quelque raison que ce soit.
- À n'utiliser les données relatives aux FOURNISSEURS (notamment les DOCUMENTS OBLIGATOIRES, ou tout autre DOCUMENT SPÉCIFIQUE ou DOCUMENTS COMMERCIAUX) sans l'accord exprès de FREELANCE.COM
- L'utilisation de la PLATEFORME ne confère aucun autre droit de propriété intellectuelle à l'exception de l'utilisation de la PLATEFORME qui est soumise par ailleurs à l'acceptation préalable des CGU par le DONNEUR D'ORDRES.
- Dès la cessation de l'abonnement du DONNEUR D'ORDRES ou le droit d'accès à la PLATEFORME, pour quelque raison que ce soit, ce dernier s'engage expressément à ne plus utiliser la PLATEFORME ni son contenu, à quelque titre que ce soit.
- LE DONNEUR D'ORDRE S'ENGAGE A FAIRE UN USAGE CONFORME AUX LOIS ET RÈGLEMENTS DE TOUT DOCUMENT OU INFORMATION QU'IL EXTRAIT DE LA PLATEFORME, ET ASSUMERA TOUTE RESPONSABILITÉ CONSÉQUENTE A CET USAGE.
- Le DONNEUR D'ORDRES s'engage à utiliser la PLATEFORME à des fins strictement personnelles et commerciales.

### L'ACCÈS AU SERVICE DE LA PLATEFORME PAR LE DONNEUR D'ORDRES

L'accès du service par le DONNEUR D'ORDRES est subordonné à l'acceptation des présentes CGU.

### PRESTATIONS DONNEUR D'ORDRES – PRIX ET MODALITÉS DE PAIEMENT :

Le DONNEUR D'ORDRES peut accéder à :

- La PLATEFORME avec un espace privé
- La possibilité d'avoir accès en H24/365J à toute publication de la PLATEFORME sauf interruption intempestive de la plateforme, dans les conditions des présentes CGU ;
- La faculté de faire, au moyen d'un moteur de recherches, des tris et des recherches multicritères parmi des CV.
- Des demandes de besoins en matière de ressources pour des mission, cette demande est adressée via un fichier à compléter, fourni par la PLATEFORME et adressé à TALENT ACQUISITION ;
- Mise à disposition d'un espace de communication avec la PLATEFORME avec la possibilité d'échange de fichiers.
- Accès aux données concernant les missions et les intervenants dans le cadre des commandes conclues et à conclure

En cas de demande de besoin en matière de ressources, le DONNEUR D'ORDRE reçoit les propositions par le service TALENT ACQUISITION en réponse à aux demandes qu'il aura préalablement formulées sur la PLATEFORME.

### L'ACCÈS AU SERVICE DE LA PLATEFORME AU FOURNISSEUR

Le FOURNISSEUR peut être amené à déposer son CV, celui-ci sera accessible au(x) DONNEUR(S) D'ORDRE(S) sous réserve du respect des prescriptions qui suivent :

- Le ou les CVs doivent impérativement répondre aux prescriptions légales Françaises et internationales.
- Le ou les CVs doivent impérativement contenir les mentions minimums indiquées dans la PLATEFORME et dans les présentes CGU.
- Le ou les CVs doivent impérativement être conformes aux règles régissant la lutte contre toute discrimination de quelque nature qu'elle soit.
- Le ou les CVs doivent impérativement contenir des informations en rapport direct avec l'objet de la PLATEFORME.
- Le ou les CVs doivent impérativement respecter en tout état de cause l'ensemble des prescriptions des présentes CGU.

La PLATEFORME FREELANCE.COM, se réserve tout droit de modération, de suspension, ou de radiation d'un CV, ne répondant pas aux prescriptions précitées ou toute autre prescription exprimée dans les présentes CGU, sans préjudice de toute action judiciaire en dommages et intérêts.

### LES PRESTATIONS RELATIVES AU FOURNISSEUR

Le FOURNISSEUR bénéficiera sous réserve des conditions prescrites dans les présentes CGU de :

- La possibilité de vérifier en H24/365J sauf interruption intempestive de la plateforme toute publication et information, dans les conditions des présentes CGU ;
- La possibilité de contacter les différents services et sociétés du groupe FREELANCE.COM
- Mise à disposition d'un espace de communication avec la possibilité d'échanger des fichiers.

Le FOURNISSEUR accepte expressément et sans aucune réserve la procédure et modalités mise en vigueur par la PLATEFORME et par chaque service ou société du groupe FREELANCE.COM qu'il aura contacté.

Le FOURNISSEUR s'engage à ce titre au respect dans les règles éthiques et loyales dans le cadre de la procédure précitée.

### L'ACCES AU SERVICE PAR LE CONSULTANT CANDIDAT AU PORTAGE SALARIAL

Le CONSULTANT peut être amené à déposer son CV, celui-ci sera recevable sous réserve du respect des prescriptions qui suivent :

- Le ou les CVs doivent impérativement répondre aux prescriptions légales Françaises et internationales.
- Le ou les CVs doivent impérativement contenir les mentions minimums indiquées dans la PLATEFORME et dans les présentes CGU.
- Le ou les CVs doivent impérativement être conformes aux règles régissant la lutte contre toute discrimination de quelque nature qu'elle soit.
- Le ou les CVs doivent impérativement contenir des informations en rapport direct avec l'objet de la PLATEFORME.
- Le ou les CVs doivent impérativement respecter en tout état de cause l'ensemble des prescriptions des présentes CGU.

Le CONSULTANT adresse les coordonnées complètes de son Client et de la personne concernée afin que la société de portage salarial du groupe FREELANCE.COM puisse établir le 1er contact et conclure le contrat de prestation de service en portage salarial avec le client du CONSULTANT.

### MODÉRATION DES CVS

FREELANCE.COM peut contrôler et vérifier la conformité des profils et/ou CVs déposés par le FOURNISSEUR/UTILISATEUR/PRESTATAIRE, à la finalité et aux dispositions contractuelles de FREELANCE.COM.

Tout profil et/ou CV non conforme à la finalité et aux dispositions contractuelles de FREELANCE.COM pourra être suspendu.

La conformité des profils et/ou CVs des prestataires est définie de façon non exhaustive de la manière suivante :

- Le contenu des profils et/ou CVs doit être le plus complet possible, avec des indications précises du parcours professionnel et des spécialités éventuelles,
- Le contenu des profils et/ou CVs doit contenir toute information et coordonnées permettant d'identifier et/ou de contacter le FOURNISSEUR/UTILISATEUR directement,
- Le contenu du profil et/ou CVs doit être mis à jour régulièrement, et autant de fois qu'un changement professionnel, de coordonnées etc…interviennent dans le parcours de l'UTILISTATEUR.
- Le contenu des profils et/ou CVs ne doit pas nuire à l'image de FREELANCE.COM,
- Le contenu des profils et/ou CVs ne peut être fantaisiste,
- Le contenu des profils et/ou CVs ne peut être contraire aux dispositions légales ou à l'ordre public,
- Le contenu des profils et/ou CVs doit être exprimé et saisi de façon intelligible,
- Le contenu des profils et/ou CVs ne doit contenir aucune mention discriminatoire de quelque nature qu'elle soit,
- Le contenu des profils et/ou CVs doit être rédigé dans une des langues supportées par le site FREELANCE.COM notamment le Français et l'anglais.

#### Procédure

Les profils et/ou CVs dont le contenu contrevient aux dispositions du présent article seront temporairement ou définitivement retirés de la plateforme FREELANCE.COM.

Le FOURNISSEUR/UTILISATEUR/ sera informé de cette décision par voie d'email, dans un délai raisonnable, et pourra éventuellement y remédier en modifiant le contenu de son CV. Il devra ensuite demander une nouvelle modération afin de pouvoir réintégrer son profil/CV dans la base de prestataires de FREELANCE.COM.

FREELANCE.COM est par ailleurs expressément autorisée à masquer tout ou partie des CV's transmis.

### MISE EN ŒUVRE DE L'ENVIRONNEMENT TECHNIQUE DE LA PLATEFORME

FREELANCE.COM s'engage à mettre en œuvre et entretenir l'environnement technique nécessaire au bon fonctionnement de la PLATEFORME.

A ce titre, FREELANCE.COM assure la maintenance du matériel, des logiciels et des services nécessaires pour transmettre, recevoir, traduire, enregistrer et conserver les messages FREELANCE.COM.

FREELANCE.COM garantit la mise en œuvre des moyens appropriés au bon fonctionnement de la PLATEFORME afin que les objectifs décrits ci-après soient atteints :

- Disponibilité de la PLATEFORME à plus de 98% par mois.
- Sécurité des fichiers par l'emploi et la réalisation de sauvegardes
- Ergonomie optimale de la plateforme.
- Service administratif de 9 heures à 18 heures, du lundi au vendredi inclus.

FREELANCE.COM s'engage à mettre en œuvre et à maintenir des procédures et des mesures de sécurité suffisantes afin d'assurer la protection des données communiquées par les FOURNISSEURS ou les DONNEURS D'ORDRES contre les risques d'accès non autorisés, de modifications, de destruction ou de perte.

L'UTILISATEUR est informé qu'un ou plusieurs cookies, ne contenant aucune information personnelle, pourraient être placés sur son disque dur afin notamment d'assurer son identification et l'historique de ses modifications. Les procédures et les mesures de sécurité comprennent notamment la vérification de l'origine, la vérification de l'intégrité, la non-répudiation de l'origine de la réception et la confidentialité des données communiquées à l'égard des tiers non autorisés à y accéder.

Si les procédures et mesures de sécurité mises en place par FREELANCE.COM conduisent au rejet d'un ou de plusieurs documents ou à la détection d'une erreur dans ces documents, FREELANCE.COM informera LE FOURNISSEUR dans un délai raisonnable.

Les fonctionnalités de la PLATEFORME et son évolution sont définies par FREELANCE.COM.

L'évolution de la PLATEFORME est envisagée par ailleurs dans sa dimension technique et fonctionnelle. A ce titre FREELANCE.COM prendra discrétionnairement toute décision sur le plan ergonomique, technique, esthétique, fonctionnel, etc. qu'elle jugera appropriée pour la bonne évolution de la PLATEFORME.

L'UTILISATEUR déclare consentir par les présentes à toute évolution technique, graphique ou fonctionnelle permettant une optimisation du fonctionnement de la PLATEFORME.

### PREUVE

Il est expressément convenu que les données archivées et les données mises en ligne et conservées dans le système d'information de FREELANCE.COM, notamment dans ses outils de messagerie électronique, ont force probante quant aux documents envoyés, aux commandes passées et à l'exécution des obligations des parties.

Les données sur support informatique ou électronique conservées par FREELANCE.COM constituent des preuves. Si elles sont produites comme moyens de preuve par FREELANCE.COM dans toute procédure contentieuse ou autre, elles seront recevables, valables et opposables entre les parties de la même manière, dans les mêmes conditions et avec la même force probante que tout document qui serait établi, reçu ou conservé par écrit.

### RESPONSABILITÉ

S'agissant de l'utilisation de la PLATEFORME

#### Responsabilité et garanties de FREELANCE.COM

FREELANCE.COM ne garantit pas que la PLATEFORME sera exempte d'anomalies, d'erreurs ou de bugs, ni que ceux-ci pourront être corrigés, ni que la PLATEFORME fonctionnera sans interruption ou pannes, ni encore qu'elle est compatible avec un matériel ou une configuration particulière autre que ceux expressément mentionnés par FREELANCE.COM.

FREELANCE.COM ne garantit pas de délai de mise en ligne des DOCUMENTS OBLIGATOIRES ou de tous autres documents transmis par le FOURNISSEUR ou le DONNEUR D'ORDRES et ne pourra être tenu responsable en cas de retard dans la mise en ligne des documents transmis.

FREELANCE.COM n'est en aucun cas responsable de dysfonctionnements imputables à des logiciels de tiers.

En aucun cas, FREELANCE.COM ne peut être responsable des dommages quels qu'ils soient prévisibles ou imprévisibles, matériels ou immatériels (incluant notamment la perte de profits ou d'opportunités) découlant de l'utilisation ou de l'impossibilité totale ou partielle d'utiliser la PLATEFORME.

De même, FREELANCE.COM ne pouvant contrôler l'ensemble des sites sur lesquels la PLATEFORME renvoie par l'intermédiaire de liens hypertextes, qui n'existent que pour faciliter l'utilisation de la PLATEFORME par l'UTILISATEUR, FREELANCE.COM n'est en aucun cas responsable de leur contenu.

FREELANCE.COM ne saurait en aucun cas voir sa responsabilité engagée pour toute inexécution ou mauvaise exécution de tout ou partie des prestations prévues aux CGU, notamment si celles-ci seraient imputables soit au fait de l'UTILISATEUR, soit au fait imprévisible et insurmontable d'un tiers étranger à la prestation, soit à un cas de force majeure.

FREELANCE.COM ne saurait en aucun cas voir sa responsabilité engagée dans le cadre de la relation contractuelle et/ou extracontractuelle conclue le cas échéant entre les DONNEURS D'ORDRES et les FOURNISSEURS, FREELANCE.COM étant à ce titre strictement tiers à leur relation quelle que soit sa nature.

### RESPONSABILITÉ ET GARANTIES DE L'UTILISATEUR

L'UTILISATEUR déclare connaître les caractéristiques et les limites de l'internet, en particulier ses performances techniques, les temps de réponse pour consulter, interroger ou transférer des données et les risques liés à la sécurité des communications.

L'UTILISATEUR reconnait avoir vérifié que la configuration informatique qu'il utilise ne contient aucun virus et qu'elle est en parfait état de fonctionnement.

L'UTILISATEUR n'a pas le droit d'utiliser la PLATEFORME de façon à la rendre inaccessible, à entraver son fonctionnement, à la modifier ou à l'endommager de quelque façon que ce soit.

L'UTILISATEUR n'a pas le droit d'utiliser la PLATEFORME de façon illicite, à des fins frauduleuses ou en relation avec une activité illégale, notamment : (i) pour envoyer, utiliser, importer ou exporter des fichiers, des contenus ou des propos illicites, offensants, indécents, injurieux, diffamatoires, obscènes, menaçant ou portant au droits de propriété intellectuelle, aux droits de la personnalité ou au droit à l'image de tiers, (ii) pour envoyer ou utiliser un virus informatique, des opinions politiques, des publicités, des chaînes de courriers, (iii) pour procéder à des envois en masse de contenus ou de courriers non sollicités et, plus généralement, pour procéder à toute forme de spamming, (iv) pour importuner ou menacer les autres UTILISATEURS de la PLATEFORME, (v) pour utiliser, mettre à disposition, télécharger de faux documents.

### SUSPENSION OU LIMITATION DE L'ACCÈS À LA PLATEFORME

FREELANCE.COM peut suspendre ou limiter l'accès de l'UTILISATEUR à la PLATEFORME, de façon discrétionnaire, notamment en cas de violation par l'UTILISATEUR des CGU ou en cas de violation de la proposition commerciale.

FREELANCE.COM se réserve en outre le droit de suspendre ou de limiter l'accès à la PLATEFORME pour des raisons de maintenance ou pour permettre la mise en place de nouvelles fonctionnalités.

### PROCÉDURE DE COMMUNICATION

La communication entre FREELANCE.COM et les UTILISATEURS peut être de nature électronique, postale, téléphonique ou s'effectuer par tout autre moyen offert par la technique présente et à venir.

Aucune communication écrite ou orale ne pourra contrevenir aux dispositions des Contrats conclus.

S'agissant de la communication électronique un accusé de réception n'est pas requis, sauf s'il est demandé.

Lorsqu'un accusé de réception est requis, le destinataire du message FREELANCE.COM doit s'assurer que l'accusé de réception est envoyé dans un délai de 24 heures, [un] jour ouvrable, à compter du moment de réception du message dont il y a lieu d'accuser réception, à moins qu'un autre délai ne soit spécifié dans l'annexe technique.

Un jour ouvrable signifie un jour autre que le samedi, le dimanche ou tout autre jour férié officiel au lieu de destination prévu du message FREELANCE.COM.

Un message FREELANCE.COM assorti d'une demande d'accusé de réception ne peut pas être exécuté par le destinataire du message avant que l'accusé de réception ne soit envoyé.

Si l'expéditeur ne reçoit pas l'accusé de réception dans le délai prévu, il est en droit, sous réserve d'en aviser le destinataire, de considérer le message FREELANCE.COM comme nul et non avenu à compter de l'expiration dudit délai, ou de lancer une procédure de récupération prévue à l'annexe technique pour assurer la réception effective de l'accusé de réception.

Si la procédure de récupération échoue dans le délai imparti, le message FREELANCE.COM est définitivement considéré comme nul et non avenu à compter de l'expiration dudit délai, sous réserve que le destinataire en soit avisé.

Les écrits inhérents à cette communication constituent par ailleurs des moyens de preuves, régis par les dispositions du Code civil Français.

### PROPRIÉTÉ INTELLECTUELLE

**Propriété de la marque**

La marque FREELANCE.COM :

« FREELANCE.COM » est une marque déposée. Toute reproduction ou transfert non autorisés de ses marques, logos et signes publiées ou non sur la PLATEFORME constituent une contrefaçon passible de sanctions pénales. Le contrevenant s'expose à des sanctions civiles et pénales et notamment aux peines prévues aux articles L. 335.2 et L. 343.1 du code de la Propriété Intellectuelle.

Tout UTILISATEUR de la plateforme FREELANCE.COM, dont le dossier est à jour, et qui ne fait l'objet d'aucune suspension ni radiation, peut utiliser le nom et la marque FREELANCE.COM sur ses documents commerciaux, pour signaler la présence des DOCUMENTS OBLIGATOIRES ou tout autre document ou information répondant aux dispositions des présentes CGU et de celles de la plateforme FREELANCE.COM.

#### Marque et données personnelles de l'UTILISATEUR :

L'UTILISATEUR s'engage dans les présentes à céder à FREELANCE.COM tout droit à utiliser sa marque et ses données personnelles mises en ligne dans un but strictement réservé à l'exploitation optimale de sa plateforme et ce, sans réserve ni exception.

#### Autres utilisations

Toute utilisation du nom FREELANCE.COM ou du logo FREELANCE.COM ou toute autre marque ou logo lui appartenant ne pourra être faite sans l'autorisation exprès et préalable de la société FREELANCE.COM sous peines de poursuites pénales de contrefaçon et/ou de concurrence déloyale.

### Informatique et libertés

1. Cette politique a notamment pour objet d'informer les utilisateurs, des modalités de collecte, de traitement, et d'utilisation de leurs données personnelles et de leurs droits en matière de protection des données à caractère personnel au regard des dispositions applicables en la matière, notamment le règlement n° 2016/679 du 27 avril 2016 sur la protection des données et la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés dite « Informatique et libertés » telle que modifiée par la loi n° 2018-493 du 20 juin 2018, promulguée le 21 juin 2018.
2. **Qui sommes-nous ?**

Les informations suivantes vous sont communiquées afin que vous puissiez prendre connaissance des engagements en matière de protection des données à caractère personnel de FREELANCE.COM, société par actions simplifiée dont le siège social est situé 1 Parvis de la Défense 92044 Paris la Défense Cedex France qui agit en tant que responsable du traitement pour les traitements de données à caractère personnel évoqués dans le présent document.

1. **Qui est notre délégué à la protection des données ?**

FREELANCE.COM a désigné un délégué à la protection des données personnelles dont les coordonnées sont les suivantes : Georges Cherfan (dpo@freelance.com).

1. **Quand traitons-nous des données ?**

Les données vous concernant sont notamment collectées ou traitées en tout ou partie à l'occasion de :

- la création de votre compte sur le site,
- le dépôt d'une annonce,
- l'accès à toute partie du site nécessitant une authentification,
- l'utilisation de tout service du site nécessitant une authentification.

Les données sont collectées directement auprès de vous, à votre demande et lors de l'utilisation du site internet de FREELANCE.COM.

1. **Quelles catégories de données collectées traitons-nous ?**

Dans le cadre de l'utilisation du site internet et des services associés, plusieurs types de données à caractère personnel peuvent être collectés.

Principalement les données collectées correspondant aux catégories suivantes :

- données relatives à l'identité (nom, prénom, adresse, adresse mail, etc.) ,
- données relatives aux moyens de paiement,
- données relatives au suivi de la relation commerciale,
- données de connexion (date, heure, adresse Internet, protocole de l'ordinateur du visiteur, page consultée etc.)

Les données obligatoires sont signalées dans les formulaires de collecte par un astérisque. En leur absence, la prestation liée à cette collecte pourrait ne pas être fournie.

1. **Pour quelles finalités les données sont-elles collectées ?**

Selon les cas, FREELANCE.COM traite vos données en tout ou partie pour les principales finalités suivantes :

- la gestion et le suivi de la création de compte et de la relation contractuelle de manière générale,
- la gestion et le suivi des commandes, paiements ainsi que la relation clientèle,
- la gestion des comptes Clients, et FOURNISSEURS
- la gestion commerciale et marketing,
- la mesure de la qualité et de la satisfaction,
- la prospection commerciale et la personnalisation des offres.

Analyses / Etudes / Rapports liés à l'activité de FREELANCE.COM

1. **Quels sont les fondements juridiques de nos traitements ?**

Les données ainsi collectées sont indispensables à ces traitements et sont destinées aux services concernés de FREELANCE.COM, ainsi que, le cas échéant, à ses sous-traitants ou prestataires.

Nous ne mettons en œuvre des traitements de données que si au moins l'une des conditions suivantes est remplie :

- votre consentement aux opérations de traitement a été recueilli ;
- l'existence de notre intérêt légitime, ou de celui d'un tiers, qui justifie que nous mettions en œuvre le traitement de données à caractère personnel concerné ;
- l'exécution d'un contrat qui nous lie à vous nécessite que nous mettions en œuvre le traitement de données à caractère personnel concerné ;
- nous sommes tenus par des obligations légales et réglementaires qui nécessitent la mise en œuvre du traitement de données à caractère personnel concerné.

1. **A qui sont-elles transmises ? Qui sont les destinataires de vos données ?**

Les données à caractère personnel que nous collectons, de même que celles qui sont recueillies ultérieurement, nous sont destinées en notre qualité de responsable du traitement.

Les données traitées sont destinées selon les cas aux personnes suivantes :

- notre service client,
- éventuels sous-traitants et partenaires contractuels et commerciaux.

Par ailleurs, les données pourront être communiquées à toute autorité légalement habilitée à en connaître en particulier en cas de réquisition judiciaire des autorités judiciaires, policières ou administratives.

Nous veillons à ce que seules les personnes habilitées puissent avoir accès à ces données. Nos prestataires de services peuvent être destinataires de ces données pour réaliser les prestations que nous leur confions. Certaines données personnelles peuvent être adressées à des tiers ou à des autorités légalement habilitées et ce pour satisfaire nos obligations légales, réglementaires ou conventionnelles.

1. **Combien de temps les données sont-elles conservées ?**

FREELANCE.COM conserve vos données pour une durée proportionnée aux finalités pour lesquelles vos données ont été collectées.

Les données collectées sont conservées pendant toute la durée des relations contractuelles et sont supprimées à la terminaison des relations contractuelles, à l'exception des données permettant d'établir la preuve des relations contractuelles et qui sont conservées jusqu'à la fin des délais de prescription en vigueur.

1. **Quels sont vos droits ?**

Conformément aux dispositions du règlement n° 2016/679 du 27 avril 2016 sur la protection des données et la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés dite « Informatique et libertés » telle que modifiée par la loi n° 2018-493 du 20 juin 2018, promulguée le 21 juin 2018, vous disposez :

1. d'un droit à l'information :
1. Vous reconnaissez que la présente notice légale vous informe des finalités, du cadre légal, des intérêts, des destinataires ou catégories de destinataires avec lesquels sont partagées vos données personnelles, et de la possibilité d'un transfert de données vers un pays tiers ou à une organisation internationale.
2. En plus de ces informations et dans l'objectif de garantir un traitement équitable et transparent de vos données, vous déclarez avoir reçu des informations complémentaires concernant :
   - la durée de conservation de vos données personnelles ;
   - l'existence des droits qui sont reconnus à votre bénéfice et des modalités de leur exercice.
3. Si nous décidons de traiter des données pour des finalités autres que celles indiquées, toutes les informations relatives à ces nouvelles finalités vous seront communiquées.
2. d'un droit d'accès, d'interrogation et de rectification :
1. Vous disposez du droit d'accéder et de faire rectifier vos données personnelles, que vous pouvez exercer auprès de FREELANCE.COM à l'adresse suivante : dpo@freelance.com
2. A ce titre, vous avez la confirmation que vos données personnelles sont ou ne sont pas traitées et lorsqu'elles le sont, et disposez de l'accès à vos données ainsi qu'aux informations concernant :
   - les finalités du traitement ;
   - les catégories de données personnelles concernées ;
   - les destinataires ou catégories de destinataires ainsi que les organisations internationales auxquels les données personnelles ont été ou seront communiquées, en particulier les destinataires qui sont établis dans des pays tiers ;
   - lorsque cela est possible, la durée de conservation des données personnelles envisagée ou, lorsque ce n'est pas possible, les critères utilisés pour déterminer cette durée ;
   - l'existence du droit de demander au responsable du traitement la rectification ou l'effacement de vos données personnelles, du droit de demander une limitation du traitement de vos données personnelles, du droit de vous opposer à ce traitement ;
   - le droit d'introduire une réclamation auprès d'une autorité de contrôle ;
   - des informations relatives à la source des données quand elles ne sont pas collectées directement auprès des personnes concernées ;
   - l'existence d'une prise de décision automatisée, y compris de profilage, et dans ce dernier cas, des informations utiles concernant la logique sous-jacente, ainsi que l'importance et les conséquences prévues de ce traitement pour les personnes concernées.
3. Vous pouvez nous demander que vos données personnelles soient, selon les cas, rectifiées, complétées si elles sont inexactes, incomplètes, équivoques, périmées.
3. d'un droit à l'effacement des données vous concernant ;
1. Vous pouvez nous demander l'effacement de vos données personnelles lorsque l'un des motifs suivants s'applique :
   - les données personnelles ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées d'une autre manière ;
   - vous retirez le consentement préalablement donné ;
   - vous vous opposez au traitement de vos données personnelles lorsqu'il n'existe pas de motif légal audit traitement ;
   - le traitement de données personnelles n'est pas conforme aux dispositions de la législation et de la réglementation applicable ;
   - vos données personnelles ont été collectées dans le cadre de l'offre de services de la société de l'information aux enfants âgés de moins de 16 ans.
2. Néanmoins, l'exercice de ce droit ne sera pas possible lorsque la conservation de vos données personnelles est nécessaire au regard de la législation ou de la réglementation et notamment par exemple pour la constatation, l'exercice ou la défense de droits en justice.
4. d'un droit à la limitation des traitements de données :
1. Vous pouvez demander la limitation du traitement de vos données personnelles dans les cas prévus par la législation et la réglementation.
5. d'un droit d'opposition aux traitements de données :
1. Vous disposez également d'un droit d'opposition pour des raisons tenant à votre situation particulière et d'un droit d'opposition à tout moment lorsque les données sont traitées à des fins de prospection commerciale.
6. d'un droit à la portabilité de vos données :
1. Les données sur lesquelles peut s'exercer ce droit sont :
   - uniquement vos données personnelles, ce qui exclut les données personnelles anonymisées ou les données qui ne vous concernent pas ;
   - les données personnelles déclaratives ainsi que les données personnelles de fonctionnement évoquées précédemment ;
   - les données personnelles qui ne portent pas atteinte aux droits et libertés de tiers telles que celles protégées par le secret des affaires.
2. Ce droit est limité aux traitements basés sur le consentement ou sur un contrat ainsi qu'aux données personnelles que vous avez personnellement générées.
3. Ce droit n'inclut ni les données dérivées ni les données inférées, qui sont des données personnelles créées par FREELANCE.COM.
7. d'un droit de retirer votre consentement :
1. Lorsque les traitements de données que nous mettons en œuvre sont fondés sur votre consentement, vous pouvez le retirer à n'importe quel moment. Nous cessons alors de traiter vos données à caractère personnel sans que les opérations antérieures pour lesquelles vous aviez consenti ne soient remises en cause.
8. d'un droit d'introduire un recours :
1. Vous avez le droit d'introduire une réclamation auprès de la Cnil sur le territoire français et ce sans préjudice de tout autre recours administratif ou juridictionnel.
9. d'un droit de définir des directives post-mortem
1. Vous avez la possibilité de définir des directives relatives à la conservation, à l'effacement et à la communication de vos données personnelles après votre décès et ce auprès d'un tiers de confiance, certifié et chargé de faire respecter la volonté du défunt, conformément aux exigences du cadre juridique applicable.

1. **Comment exercer vos droits ?**
1. Vous pouvez exercer vos droits en vous adressant à l'adresse mél suivante : dpo@freelance.com ou à l'adresse postale suivante : 1 Parvis de la Défense France 92044 Paris la Défense Cedex
2. Pour ce faire, vous devez indiquer clairement vos nom(s) et prénom(s), l'adresse à laquelle vous souhaitez que la réponse vous soit envoyée et y joindre la photocopie d'un titre d'identité portant votre signature.
3. Dans un souci de confidentialité et de protection des données personnelles, FREELANCE.COM doit s'assurer de l'identité de l'utilisateur avant de répondre à sa demande. Aussi, toute demande tendant à l'exercice de ces droits devra être accompagnée d'une copie d'un titre d'identité signé.
4. Par principe, vous pouvez exercer sans frais l'ensemble de vos droits. Cependant en matière de droit d'accès, il pourra vous être demandé le paiement de frais raisonnables basés sur les coûts administratifs pour toute copie des données que vous demanderez.
5. Concernant le droit d'information, FREELANCE.COM n'aura pas l'obligation d'y donner suite lorsque vous disposez déjà des informations dont vous sollicitez la communication.
6. FREELANCE.COM vous informera s'il ne peut donner suite à vos demandes.
7. FREELANCE.COM tient à vous informer que le non-renseignement ou la modification de vos données sont susceptibles d'avoir des conséquences dans le traitement de certaines demandes dans le cadre de l'exécution des relations contractuelles et que votre demande au titre de l'exercice de vos droits sera conservée à des fins de suivi.

### POLITIQUE DE GESTION DES COOKIES

L'UTILISATEUR est informé et accepte expressément qu'un ou plusieurs cookies, ne contenant aucune information personnelle, pourraient être placés sur son disque dur afin notamment d'assurer son identification et l'historique de ses modifications. Les procédures et les mesures de sécurité comprennent notamment la vérification de l'origine, la vérification de l'intégrité, la non-répudiation de l'origine de la réception et la confidentialité des données communiquées à l'égard des tiers non autorisés à y accéder. Si vous souhaitez supprimer les cookies et bloquer leurs collectes vous pouvez vous référer à la rubrique d'aide de votre navigateur.

Lors de votre première connexion sur le site FREELANCE.COM, vous êtes avertis par un bandeau en bas de votre écran ou par une fenêtre au centre de votre écran que des informations relatives à votre navigation sont susceptibles d'être enregistrées dans des fichiers dénommés « cookies ». Notre politique d'utilisation des cookies vous permet de mieux comprendre les dispositions que nous mettons en œuvre en matière de navigation sur notre site web. Elle vous informe notamment sur l'ensemble des cookies présents sur notre site web, leur finalité et vous donne la marche à suivre pour les paramétrer

#### INFORMATIONS GÉNÉRALES SUR LES COOKIES PRÉSENTS SUR LE SITE FREELANCE.COM

FREELANCE.COM pourra procéder à l'implantation d'un cookie sur le disque dur de votre terminal (ordinateur, tablette, mobile etc.) afin de vous garantir une navigation fluide, adaptée et optimale sur notre site Internet.

Les « cookies » (ou témoins de connexion) sont des petits fichiers texte de taille limitée qui permettent de reconnaître votre ordinateur, votre tablette ou votre mobile aux fins de personnaliser les services que nous vous proposons.

Les informations recueillies par le biais des cookies ne permettent en aucune manière de vous identifier nominativement. Elles sont utilisées exclusivement pour nos besoins propres afin d'améliorer l'interactivité et la performance de notre site web et de vous adresser des contenus adaptés à vos centres d'intérêts. Aucune de ces informations ne fait l'objet d'une communication auprès de tiers sauf lorsque FREELANCE.COM a obtenu au préalable votre consentement ou bien lorsque la divulgation de ces informations est requise par la loi, sur ordre d'un tribunal ou toute autorité administrative ou judiciaire habilitée à en connaître.

#### CONFIGURATION DE VOS PRÉFÉRENCES SUR LES COOKIES

Vous pouvez accepter, refuser le dépôt de cookies ou le paramétrer à tout moment.

Lors de votre première connexion sur le site FREELANCE.COM, une bannière présentant des informations relatives au dépôt de cookies et de technologies similaires apparaît sur votre écran. Une bannière vous indiquera la possibilité d'accepter ou de refuser les cookies.

Selon le type de cookie en cause, le recueil de votre consentement au dépôt et à la lecture de cookies sur votre terminal peut être impératif.

**Les cookies exemptés de consentement**

Conformément aux recommandations de la Commission Nationale de l'Informatique et des Libertés (CNIL), certains cookies sont dispensés du recueil préalable de votre consentement dans le sens de l'article 82 de la loi informatique et liberté, dans la mesure où ils sont strictement nécessaires au fonctionnement du site internet ou ont pour finalité exclusive de permettre ou faciliter la communication par voie électronique. Il s'agit notamment des cookies d'identifiant de session, d'authentification, de session d'équilibrage de charge ainsi que des cookies de personnalisation de votre interface. Ces cookies sont intégralement soumis à la présente politique dans la mesure où ils sont émis et gérés par FREELANCE.COM.

**Les cookies nécessitant le recueil préalable de votre consentement**

Cette exigence concerne les cookies émis par des tiers et qui sont qualifiés de « persistants » dans la mesure où ils demeurent dans votre terminal jusqu'à leur effacement ou leur date d'expiration.

De tels cookies étant émis par des tiers (dont la liste exhaustive est établie ci-après), leur utilisation et leur dépôt sont soumis à leurs propres politiques de confidentialité.

1. Les cookies de mesure d'audience établissent des statistiques concernant la fréquentation et l'utilisation de divers éléments du site web (comme les contenus/pages que vous avez visité). Ces données participent à l'amélioration de l'ergonomie du site FREELANCE.COM. Un outil de mesure d'audience est utilisé sur le présent site internet :

- HotJar dont la politique de confidentialité est disponible à partir du lien suivant : https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies
- Facebook, dont vous pouvez consulter la politique en matière de cookies en cliquant sur le lien suivant : https://fr-fr.facebook.com/policies/cookies/
- LinkedIn, dont vous trouverez la politique relative aux cookies en cliquant sur le lien suivant : https://www.linkedin.com/legal/cookie-policy?\_l=fr\_FR
- Twitter, dont vous trouverez les options dédiées au contrôle ou à la restriction de l'utilisation des cookies ainsi que la politique d'utilisation des cookies : [https://support.twitter.com/articles/20170518#](https://support.twitter.com/articles/20170518)
- Mixpanel, dont vous pouvez trouver la politique de confidentialité : https://mixpanel.com/legal/privacy-overview/
- Intercom, dont vous pouvez trouver la politique de confidentialité : https://www.intercom.com/fr/legal/privacy

1. Les cookies de solutions de marketing automation telles que Hubspot. Sous réserve de votre consentement, ces cookies permettent de vous envoyer des emailings commerciaux qui font notamment suite à une demande de votre part concernant le téléchargement d'un document sur le site FREELANCE.COM tels que livre blanc, guide…

Hubspot permet notamment de personnaliser la démarche marketing et commerciale de FREELANCE.COM en fonction de vos données.

La politique de confidentialité de Hubspot est disponible à l'adresse suivante ; https://legal.hubspot.com/fr/privacy-policy

**Vous disposez de divers outils de paramétrage des cookies**

La plupart des navigateurs Internet sont configurés par défaut de façon à ce que le dépôt de cookies soit autorisé. Votre navigateur vous offre l'opportunité de modifier ces paramètres standards de manière à ce que l'ensemble des cookies soit rejeté systématiquement ou bien à ce qu'une partie seulement des cookies soit acceptée ou refusée en fonction de leur émetteur.

ATTENTION : Nous attirons votre attention sur le fait que le refus du dépôt de cookies sur votre terminal est néanmoins susceptible d'altérer votre expérience d'utilisateur ainsi que votre accès à certains services ou fonctionnalités du présent site. Le cas échéant, FREELANCE.COM décline toute responsabilité concernant les conséquences liées à la dégradation de vos conditions de navigation qui interviennent en raison de votre choix de refuser, supprimer ou bloquer les cookies nécessaires au fonctionnement du site. Ces conséquences ne sauraient constituer un dommage et vous ne pourrez prétendre à aucune indemnité de ce fait.

Votre navigateur vous permet également de supprimer les cookies existants sur votre terminal ou encore de vous signaler lorsque de nouveaux cookies sont susceptibles d'être déposés sur votre terminal. Ces paramètres n'ont pas d'incidence sur votre navigation mais vous font perdre tout le bénéfice apporté par le cookie.

Veuillez ci-dessous prendre connaissance des multiples outils mis à votre disposition afin que vous puissiez paramétrer les cookies déposés sur votre terminal.

#### LE PARAMÉTRAGE DE VOTRE NAVIGATEUR INTERNET

Chaque navigateur Internet propose ses propres paramètres de gestion des cookies. Pour savoir de quelle manière modifier vos préférences en matière de cookies, vous trouverez ci-dessous les liens vers l'aide nécessaire pour accéder au menu de votre navigateur prévu à cet effet :

- Chrome : https://support.google.com/chrome/answer/95647?hl=fr
- Firefox : https://support.mozilla.org/fr/kb/activer-desactiver-cookies
- Internet Explorer : https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11
- Opera : http://help.opera.com/Windows/10.20/fr/cookies.html
- Safari : https://support.apple.com/kb/PH21411?viewlocale=fr\_FR&locale=fr\_FR

Il n'est pas possible d'assurer une information détaillée et à jour de ces éléments de paramétrage qui sont sous le seul contrôle exclusif et l'autorité des éditeurs des logiciels de navigation.

A titre de renseignements, les utilisateurs peuvent disposer d'informations complémentaires :

- de la part des éditeurs de leur logiciel de navigation sur leur site internet,
- de manière plus générale sur le site de la Cnil à l'adress www.cnil.fr en utilisant le mot clé « cookies » dans les moteurs de recherche.

FREELANCE.COM attire l'attention de l'utilisateur sur le fait que, lorsqu'il accepte ou s'oppose à l'installation ou à l'utilisation d'un cookie, un cookie de consentement ou de refus est installé sur son équipement terminal. Si l'utilisateur supprime ce cookie de consentement ou de refus, il ne pourra plus être identifié comme ayant accepté ou refusé l'utilisation de cookies. Les cookies de consentement ou de refus doivent rester sur l'équipent terminal de l'utilisateur.

L'utilisateur dispose d'un droit d'accès, de retrait et de modification des données à caractère personnel communiquées par le biais des cookies dans les conditions indiquées ci-dessus.

L'utilisateur est tenu de respecter les dispositions de la loi Informatique et libertés du 6 janvier 1978 modifiée par le règlement général sur la protection des données (RGPD) du 27 avril 2016 dont la violation est passible de sanctions pénales.

Il doit notamment s'abstenir, s'agissant des informations à caractère personnel auxquelles il accède ou pourrait accéder, de toute collecte, de toute utilisation détournée d'une manière générale, de tout acte susceptible de porter atteinte à la vie privée ou à la réputation des personnes.

### DISPOSITIONS DIVERSES

### Liens

1. Les utilisateurs et visiteurs du site ne peuvent pas mettre en place un lien en direction de ce site sans l'autorisation expresse et préalable de l'éditeur du site.
2. En aucun cas, cette autorisation ne pourra être qualifiée de convention implicite d'affiliation.
3. En toute hypothèse, les liens renvoyant au site devront être retirés à première demande de FREELANCE.COM.
4. FREELANCE.COM se réserve la possibilité de mettre en place des liens sur sa plateforme donnant accès à des pages web autres que celles de sa plateforme.
5. Les utilisateurs sont formellement informés que les sites auxquels ils peuvent accéder par l'intermédiaire des liens n'appartiennent pas à FREELANCE.COM.
6. FREELANCE.COM ne saurait être responsable de l'accès par les utilisateurs via les liens mis en place dans le cadre du site en direction d'autres ressources présentes sur le réseau internet, ni du contenu des informations fournies sur ces sites au titre de l'activation du lien.

### Modification notice légale

1. FREELANCE.COM se réserve le droit de modifier et d'actualiser la présente notice légale à tout moment et sans préavis.
2. L'utilisateur est donc invité à la consulter régulièrement.

### Durée de conservation

- la durée de vie des cookies est limitée à 13 mois, durée permettant une comparaison pertinente des audiences dans le temps,
- les informations collectées par l'intermédiaire des cookies est conservées pour une  **durée maximale de vingt-cinq mois**  ;
- les durées de vie et de conservation ci-dessus mentionnées fassent l'objet d'un  **réexamen périodique**  afin d'être limitées au strict nécessaire.

Pour de plus amples informations concernant les outils de maîtrise des cookies, vous pouvez consulter le site internet de la CNIL : https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.

Pour toute interrogation ou demande complémentaire d'informations relative à la présente politique des cookies, merci de bien vouloir nous contacter par le biais de notre formulaire.

### INFRACTIONS PÉNALES

Les UTILISATEURS de la plateforme FREELANCE.COM s'engagent, quelle que soit leur qualité à respecter la règlementation pénale Française en matière d'infractions caractérisées de racisme, antisémitisme et/ou toute action qualifiée de discriminatoire, ou portant atteinte à la dignité humaine.

Tout document ou données mis en ligne, en infraction à ces dispositions entrainera immédiatement la rupture du lien contractuel, et l'exclusion de l'UTILISATEUR, sans préjudice d'une action judiciaire en réparation du dommage causé.

### LIMITES DE RESPONSABILITÉ

FREELANCE.COM n'intervient d'aucune manière via sa PLATEFORME dans les relations entre DONNEURS D'ORDRES ET FOURNISSEURS, qui demeurent exclusivement libres de leurs choix et actions et déclarent à ce titre que FREELANCE.COM est strictement tiers à leurs relations contractuelles ou extracontractuelles sauf acceptation expresse de sa part de conclure un contrat avec l'un ou l'autre des CLIENTS.

Toute autre relation contractuelle, sera soumise à un accord écrit dûment signé entre les parties,

La plateforme FREELANCE.COM est une plateforme d'information, mettant en relation des UTILISATEURS du site dans les modalités et conditions exprimées par les dispositions contractuelles disponibles dans la rubrique intitulée fichier contractuel.

Les informations diffusées sur la plateforme FREELANCE.COM proviennent de sources réputées fiables. Toutefois, FREELANCE.COM ne peut garantir l'exactitude ou la pertinence de ces données. En outre, les informations mises à disposition sur cette plateforme le sont uniquement à titre purement informatif et ne sauraient constituer en aucun cas un conseil ou une recommandation de quelque nature que ce soit.

En conséquence, l'utilisation des informations et contenus disponibles sur l'ensemble de la plateforme, ne sauraient en aucun cas engager la responsabilité de FREELANCE.COM, à quelque titre que ce soit. L'UTILISATEUR est seul maître de la bonne utilisation, avec discernement et esprit de loyauté, des informations mises à sa disposition sur la Plateforme.

Par ailleurs, l'UTILISATEUR s'engage à indemniser FREELANCE.COM de toutes conséquences dommageables liées directement ou indirectement à l'usage qu'il fait du Service.

L'accès à certaines rubriques de la plateforme FREELANCE.COM nécessite l'utilisation d'un IDENTIFIANT et d'un MOT DE PASSE. Le MOT DE PASSE, choisi par l'UTILISATEUR, est personnel et confidentiel. L'UTILISATEUR s'engage à conserver secret son MOT DE PASSE et à ne pas le divulguer sous quelque forme que ce soit. L'utilisation de son IDENTIFIANT et de son MOT DE PASSE à travers internet se fait aux risques et périls de l'UTILISATEUR. Il appartient à l'UTILISATEUR de prendre toutes les dispositions nécessaires permettant de protéger ses propres données contre toute atteinte.

FREELANCE.COM s'engage néanmoins à mettre en place tous les moyens nécessaires pour garantir la sécurité et la confidentialité des données transmises. L'UTILISATEUR est informé qu'un ou plusieurs cookies, ne contenant aucune information personnelle, pourront être placés sur son disque dur afin d'assurer son identification.

L'UTILISATEUR admet connaître les limitations et contraintes propres au réseau internet et, à ce titre, reconnaît notamment l'impossibilité d'une garantie totale de la sécurisation des échanges de données. FREELANCE.COM ne pourra pas être tenue responsable des préjudices découlant de la transmission de toute information, y compris de celle de son IDENTIFIANT et/ou de son MOT DE PASSE, via le Service.

FREELANCE.COM ne pourra en aucun cas, dans la limite du droit applicable, être tenue responsable des dommages et/ou préjudices, directs ou indirects, matériels ou immatériels, ou de quelque nature que ce soit, résultant d'une indisponibilité du Service ou de toute Utilisation du Service. Le terme « Utilisation » doit être entendu au sens large, c'est-à-dire tout usage de la plateforme quel qu'il soit, licite ou non.

L'UTILISATEUR s'engage, d'une manière générale, à respecter l'ensemble de la réglementation en vigueur en France.

### LIENS HYPERTEXTES

FREELANCE.COM propose des liens hypertextes vers des plateformes web édités et/ou gérés par des tiers.

Dans la mesure où aucun contrôle n'est exercé sur ces ressources externes, l'UTILISATEUR reconnaît que FREELANCE.COM ne sera d'aucune manière responsable de dommages de quelque nature qu'ils soient, notamment relatifs à la mise à disposition de ces ressources, et ne peut être tenue responsable quant à leur contenu.

### FORCE MAJEURE

La responsabilité de FREELANCE.COM ne pourra être engagée en cas de force majeure, du fait d'un tiers ou de faits indépendants de sa volonté.

### EVOLUTION DES PRÉSENTES

FREELANCE.COM se réserve le droit de modifier les termes, conditions et mentions des présentes conditions générales à tout moment.

FREELANCE.COM informera les UTILISATEURS de toute modification des termes des présentes CGU, celles-ci seront réputées acceptées par l'UTILISATEUR dans un délai de 15 jours calendaires, sans dénonciation expresse.

Il est vivement conseillé à l'UTILISATEUR de consulter régulièrement la dernière version des Conditions Générales d'Utilisation disponible sur la plateforme.

### EVOLUTION DE LA PLATEFORME

L'UTILISATEUR déclare consentir par les présentes à toute évolution permettant une optimisation de la plateforme FREELANCE.COM.

FREELANCE.COM pourra modifier la configuration esthétique et fonctionnelle de sa plateforme. Quelles que soient ces modifications, le présent contrat continuera à produire ses effets.

Toute offre complémentaire de nouveau service pouvant le cas échéant entrainer une contrepartie financière supplémentaire pour l'UTILISATEUR fera l'objet d'une communication à l'UTILISATEUR qui sera en mesure de l'accepter. Le refus éventuel constituera si bon semble à FREELANCE.COM une résiliation du présent contrat sans indemnité de part et d'autre.

En tout état de cause l'absence de dénonciation pendant un délai de 15 jours, vaut acceptation desdites évolutions.

### RETRAIT DU DOSSIER EN LIGNE

FREELANCE.COM se réserve la faculté de retirer un dossier du site, et les références de l'UTILISATEUR FOURNISSEUR, au cas où un événement majeur le justifierait. Il en serait ainsi dans les cas où la poursuite de la mise en ligne pourrait transmettre directement son CV au DONNEUR D'ORDRES ou induire FREELANCE/COM en erreur sur la situation du FOURNISSEUR, situations telles que : fausses informations, coordonnées, fausses références, cessation de paiement, changement de dirigeant social, découverte de la non-conformité d'une pièce dans le dossier, cette liste étant non limitative.

Dans une telle situation, FREELANCE.COM suspendra la mise en ligne du dossier et mettra le FOURNISSEUR en demeure de remédier à la situation constatée. La suspension durera tant qu'un dossier modificatif en règle n'aura pas été adressé à FREELANCE.COM.

### DISPOSITIONS PARTICULIÈRES

Le fait que FREELANCE.COM ne se prévale pas, à un moment ou à un autre, d'une des dispositions des CGU ne pourra être interprété comme valant renonciation par FREELANCE.COM à s'en prévaloir ultérieurement.

Dans le cas où l'une des dispositions des CGU serait déclarée nulle ou sans effet, elle serait réputée non écrite, sans que cela n'affecte la validité des autres dispositions, sauf si la disposition déclarée nulle ou sans effet était essentielle et déterminante.

Tout cas de force majeure rendant impossible l'exécution des prestations, y compris l'interruption des moyens de télécommunications, éteint les obligations pesant sur FREELANCE.COM affectée par le cas de force majeure. Les prestations inexécutées en raison d'un cas de force majeure donnent lieu à remboursement. Toutefois cette inexécution ne peut donner lieu à aucuns dommages et intérêts.

### DROIT APPLICABLE ET JURIDICTION COMPÉTENTE

Les règles en matière de droit, applicables aux contenus et aux transmissions de données sur et autour de la plateforme, sont déterminées par la loi française. En cas de litige, n'ayant pu faire l'objet d'un accord à l'amiable, seul le tribunal de commerce de NANTERRE sera compétent.

## ANNEXE 1 : LISTE DES FILIALES

### AD'MISSIONS ACCESS

Société par actions simplifiée au capital de 229 500,00 Euros, dont le siège social est situé 1 Parvis de la Défense - 92044 Paris La Défense cedex, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro RCS 478 676 901, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET.

### AD'MISSIONS ONE SAS

Société par actions simplifiée au capital de 15 000,00 Euros, dont le siège social est situé au 1 parvis de la Défense - 92044 Paris La Défense cedex, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro RCS 837 521 442, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET.

### AD'MISSIONS ETUDES ET PROJETS

Société par actions simplifiée au capital de 50 000,00 Euros, dont le siège social est situé 4 Place Louis Armand – 75603 PARIS CEDEX 12, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 487 531 659, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET.

### AD'MISSIONS SAVOIR-FAIRE

Société par actions simplifiée au capital de 595 150 Euros, dont le siège social est situé 1 parvis de la Défense - 92044 Paris La Défense cedex, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro RCS 413 598 632, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET.

### COWORKEES

Société par actions simplifiée au capital de 10 000 euros, dont le siège social est situé 57 rue Jean Baud, 74940 Annecy France immatriculée au Registre du Commerce et des Sociétés d'Annecy, sous le numéro 824 544 977, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET.

### FREELANCE ONE

Société par actions simplifiée au capital de 50 000 euros, dont le siège social est situé 1 Parvis de la Défense, 92044 Paris le Défense cedex, immatriculée au RCS de Nanterre sous le numéro 852 264 431, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET.

### FREELANCE SERVICES

Société par actions simplifiée au capital de 50 000,00 Euros, dont le siège social est situé 1 Parvis de la Défense, 92044 Paris le Défense cedex, immatriculée au RCS de Nanterre sous le numéro 517 703 385, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET.

### INOP'S

Société par actions simplifiée au capital de 243 403,00 Euros, dont le siège social est situé 1 Parvis de la Défense, 92044 Paris le Défense cedex, immatriculée au RCS de Nanterre sous le numéro 513 082 503, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET.

### INSTITUT SAVOIR-FAIRE

Société par Actions Simplifiée, au capital de 50 000,00 Euros, dont le siège social est 1 Parvis de la Défense, 92044 Paris le Défense cedex, immatriculée au RCS de Nanterre sous le numéro 890 850 886, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET

### PROVIGIS

Société par Actions Simplifiée, au capital de 700.000,00 €. Euros dont le siège social est 4 Boulevard Diderot – 75012 PARIS, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro RCS 431 960 251, représentée par Freelance.com présidente elle-même représentée son Président Directeur Général, Monsieur Sylvestre BLAVET.

### TEOPHILE

Société par actions simplifiée au capital de 50 000 euros, dont le siège social est 1 parvis de la Défense, 92044 Paris La Défense Cedex, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 839 240 322, représentée par Freelance.com présidente elle-même représentée Monsieur Sylvestre Blavet Président Directeur Général.

### TRAINING ET MANAGEMENT CONSEIL FRANCE

Société par actions simplifiée au capital de 10 000 euros, dont le siège social est 1 parvis de la Défense, 92044 Paris La Défense Cedex, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 390 058 741, représentée par Freelance.com présidente elle-même représentée Monsieur Sylvestre Blavet Président Directeur Général.

### VALOR CONSULTANTS

Société par actions simplifiée au capital de 2 126 600 euros, dont le siège social est 1 parvis de la Défense, 92044 Paris La Défense Cedex, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 311 810 816, représentée par Freelance.com présidente elle-même représentée Monsieur Sylvestre Blavet Président Directeur Général.

### VALOR ONE

Société par actions simplifiée au capital de 169 000 euros, dont le siège social est 1 parvis de la Défense, 92044 Paris La Défense Cedex, immatriculée au Registre du Commerce et des Sociétés de Nanterre sous le numéro 505 317 503, représentée par Freelance.com présidente elle-même représentée Monsieur Sylvestre Blavet Président Directeur Général.
   `;
};
