import * as React from 'react';
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types';

import { isFocusable } from 'tabbable';

import { ErrorCircleSolid } from '@freelancelabs/icons';
import { clsx } from '@freelancelabs/utils';

import { generateUID } from '../../../helpers';
import { useInstance } from '../../../hooks';
import { FormContext } from '../../index';
import Input from './Input';

export type NodeOrBoolean = React.ReactNode | boolean;

// TODO: Add required child props to the as component
/*
interface RequiredChildProps {
    id?: string;
    error: ErrorProp;
    disabled?: boolean;
    disableChange?: boolean;
}
 */

export interface InputFieldOwnProps {
    label?: React.ReactNode;
    hint?: React.ReactNode;
    assistiveText?: React.ReactNode;
    assist?: React.ReactNode;
    disabled?: boolean;
    bigger?: boolean;
    dense?: boolean;
    id?: string;
    suffix?: React.ReactNode;
    error?: NodeOrBoolean;
    warning?: NodeOrBoolean;
    rootClassName?: string;
    labelContainerClassName?: string;
    assistContainerClassName?: string;
}

export type InputFieldProps<E extends React.ElementType> = PolymorphicPropsWithoutRef<InputFieldOwnProps, E>;
export const errorClassName = 'input-field-invalid';

const defaultElement = Input;

const InputFieldBase = <E extends React.ElementType = typeof defaultElement>(
    {
        label,
        hint,
        assist,
        assistiveText,
        disabled,
        bigger,
        dense: denseProp,
        error,
        id: idProp,
        rootClassName,
        labelContainerClassName,
        assistContainerClassName,
        warning,
        suffix,
        as,
        ...rest
    }: InputFieldProps<E>,
    ref: React.ForwardedRef<Element>
) => {
    const { dense } = React.useContext(FormContext) || {};
    const id = useInstance(() => idProp || generateUID());
    const assistiveUid = useInstance(() => generateUID());
    const labelRef = React.useRef<HTMLLabelElement>(null);
    const isDense = denseProp || dense;
    const classes = {
        root: clsx(
            'input-field-container',
            isDense && 'field-two--dense',
            disabled && 'field-two--disabled',
            Boolean(error) && errorClassName,
            Boolean(warning) && !error && 'field-two--warning',
            bigger && 'field-two--bigger',
            rootClassName
        ),
        labelContainer: clsx('input-field-label-container', labelContainerClassName),
        assistContainer: clsx('input-field-assist', isDense && 'sr-only', assistContainerClassName),
    };
    const labelElement = label && <span className="input-field-label">{label}</span>;
    const hintElement = hint && <span className="field-two-hint mlauto">{hint}</span>;

    const errorElement = error && typeof error !== 'boolean' && (
        <>
            <ErrorCircleSolid />
            <span>{error}</span>
        </>
    );
    const warningElement = warning && typeof warning !== 'boolean' && (
        <>
            <ErrorCircleSolid />
            <span>{warning}</span>
        </>
    );

    const Element: React.ElementType = as || defaultElement;
    return (
        <div className={classes.root}>
            {label || hint ? (
                <label htmlFor={id} className={classes.labelContainer} ref={labelRef}>
                    {labelElement}
                    {hintElement}
                </label>
            ) : (
                <label htmlFor={id} ref={labelRef} className="hidden" />
            )}
            <div>
                <Element
                    as={defaultElement}
                    ref={ref}
                    id={id}
                    error={error}
                    disabled={disabled}
                    aria-describedby={assistiveUid}
                    {...rest}
                />
            </div>
            {assist}
            <div
                className={classes.assistContainer}
                id={assistiveUid}
                onClick={(e) => {
                    // Ignore if it's empty or if the clicked target is focusable.
                    if (
                        (e.currentTarget === e.target && e.currentTarget.matches(':empty')) ||
                        isFocusable(e.target as Element)
                    ) {
                        return;
                    }
                    // Simulates this div being wrapped in a label. Clicks on the label element to trigger the normal
                    // click handler which the label would trigger.
                    labelRef.current?.click();
                }}
            >
                {errorElement || warningElement || (!error && !warning && assistiveText)}
            </div>
        </div>
    );
};

const InputField: <E extends React.ElementType = typeof defaultElement>(
    props: InputFieldProps<E>
) => React.ReactElement | null = React.forwardRef(InputFieldBase);

export default InputField;
