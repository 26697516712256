import * as React from 'react';

import { Menu } from '@headlessui/react';

import { clsx } from '@freelancelabs/utils';

import { Popper, PopperPlacement, PopperPosition, usePopper } from '../../atoms/popper';

export interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    anchorRef: React.RefObject<HTMLElement>;
    anchorPosition?: PopperPosition | null;
    originalPlacement?: PopperPlacement;
    isOpen?: boolean;
}
function DropdownMenu({
    anchorRef,
    children,
    anchorPosition,
    originalPlacement = 'bottom',
    isOpen = false,
}: DropdownProps) {
    const { floating, position, arrow, placement } = usePopper({
        reference:
            anchorPosition || anchorPosition === null
                ? {
                      mode: 'position',
                      value: anchorPosition,
                      anchor: anchorRef.current,
                  }
                : {
                      mode: 'element',
                      value: anchorRef.current,
                  },
        isOpen,
        originalPlacement,
    });
    return (
        <>
            {isOpen && (
                <Popper
                    isOpen
                    divRef={floating}
                    style={{ ...position, ...arrow }}
                    className={clsx('dropdown', `dropdown--${placement}`)}
                    role="dialog"
                >
                    <Menu.Items className="dropdown-menu-items">{children}</Menu.Items>
                </Popper>
            )}
        </>
    );
}

export default DropdownMenu;
