import { c, msgid } from 'ttag';

import { validateEmailAddress } from './email';
import { isNumber } from './validators';

export const requiredValidator = (value: any) =>
    value === undefined || value === null || value?.trim?.() === '' ? c('Error').t`This field is required` : '';
export const minLengthValidator = (value: string, minimumLength: number) =>
    value.length < minimumLength ? c('Error').t`This field requires a minimum of ${minimumLength} characters.` : '';
export const emailValidator = (value: string) => (!validateEmailAddress(value) ? c('Error').t`Email is not valid` : '');
export const numberValidator = (value: string) => (!isNumber(value) ? c('Error').t`Not a valid number` : '');
export const confirmPasswordValidator = (a: string, b: string) => (a !== b ? c('Error').t`Passwords do not match` : '');
export const defaultMinPasswordLength = 8;
export const getMinPasswordLengthMessage = (length = defaultMinPasswordLength) =>
    c('Validation').ngettext(
        msgid`Password must contain at least ${length} character`,
        `Password must contain at least ${length} characters`,
        length
    );
export const passwordLengthValidator = (a: string, length = defaultMinPasswordLength) =>
    a.length < length ? getMinPasswordLengthMessage() : '';
export const passwordCapitalizeValidator = (a: string) =>
    a.match(/([A-Z])/) ? '' : c('Error').t`Password required at least 1 uppercase`;
export const passwordLowerValidator = (a: string) =>
    a.match(/([a-z])/) ? '' : c('Error').t`Password required at least 1 lowercase`;
export const passwordNumberValidator = (a: string) =>
    a.match(/([0-9])/) ? '' : c('Error').t`Password required at least 1 number`;
export const passwordSpecialCharValidator = (a: string) =>
    a.match(/([+#?!@$%^&*-])/) ? '' : c('Error').t`Password required at least 1 special char`;
export const passwordValidator = (a: string) =>
    a.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[+#?!@$%^&*-]).{8,}$/)
        ? ''
        : c('Error').t`Password must match security rules`;
