import * as React from 'react';

import { ChevronDown } from '@freelancelabs/icons';
import { clsx } from '@freelancelabs/utils';

import { Button, ButtonCategory, ButtonSize } from '../../index';

interface Props {
    children: React.ReactNode;
    open: boolean;
    size?: ButtonSize;
    category?: ButtonCategory;
}

const DropdownMenuButton = ({ children, open, size, category }: Props) => {
    return (
        <Button size={size} category={category} className={clsx('button dropdown-menu-button', open && 'is-active')}>
            {children}
            <ChevronDown className="dropdown-menu-button_caret" />
        </Button>
    );
};

export default DropdownMenuButton;
