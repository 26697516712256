import * as React from 'react';

import { Button } from '@freelancelabs/components';
import { Close } from '@freelancelabs/icons';
import { clsx } from '@freelancelabs/utils';

export interface InputProps extends Omit<React.ComponentPropsWithRef<'input'>, 'prefix'> {
    error?: React.ReactNode | boolean;
    disabled?: boolean;
    unstyled?: boolean;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
    containerRef?: React.Ref<HTMLDivElement>;
    disableChange?: boolean;
    onValue?: (value: string) => void;
    inputClassName?: string;
    displayvalue?: string;
    clearable?: boolean;
}

const Input = (props: InputProps, ref: React.Ref<HTMLInputElement>) => {
    const {
        error,
        disabled,
        unstyled,
        prefix,
        suffix,
        className: classNameProp,
        inputClassName,
        onValue,
        disableChange,
        onFocus,
        onBlur,
        containerRef,
        clearable = false,
        ...rest
    } = props;

    const [focused, setFocused] = React.useState(false);

    const inputElement = (
        <input
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            aria-invalid={!!error}
            disabled={disabled}
            {...rest}
            ref={ref}
            onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                onFocus?.(event);
                setFocused(true);
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                onBlur?.(event);
                setFocused(false);
            }}
            onChange={(e) => {
                if (disableChange) {
                    return;
                }
                onValue?.(e.target.value);
                rest.onChange?.(e);
            }}
            className={clsx('input', inputClassName)}
        />
    );

    return (
        <div
            className={clsx(
                'input-wrapper',
                Boolean(error) && 'error',
                disabled && 'disabled',
                unstyled && 'unstyled',
                focused && 'is-focus',
                classNameProp
            )}
            ref={containerRef}
        >
            {prefix && <div className="field-two-input-adornment">{prefix}</div>}

            <div className="input-container">{inputElement}</div>

            {suffix && <div className="input-field-suffix">{suffix}</div>}
            {clearable && (
                <div className="input-field-suffix">
                    <Button category="ghost" size="small" icon onClick={() => onValue?.('')} type="button">
                        <Close />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default React.forwardRef<HTMLInputElement, InputProps>(Input);
