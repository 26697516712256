import React, { useState } from 'react';

import { c } from 'ttag';

import {
    AuthenticationStore,
    Button,
    InputField,
    useAuthentication,
    useFormErrors,
    useLoading,
} from '@freelancelabs/components';
import { Magic } from '@freelancelabs/icons';
import { emailValidator, requiredValidator } from '@freelancelabs/shared';
import { noop } from '@freelancelabs/utils';

interface Props {
    onSubmit: (payload: { email: string }) => void;
    defaultEmail?: string;
}

const PasswordLessForm = ({ defaultEmail = '', onSubmit }: Props) => {
    const { passwordLessLogin } = useAuthentication() as AuthenticationStore;
    const { validator, onFormSubmit } = useFormErrors();
    const [submitting, withSubmitting] = useLoading();
    const [email, setEmail] = useState(defaultEmail || '');

    const handleSubmit = () => {
        if (submitting || !onFormSubmit()) {
            return;
        }
        const run = async () => {
            await passwordLessLogin(email);
            return onSubmit({ email });
        };

        withSubmitting(run()).catch(noop);
    };

    return (
        <>
            <form
                name="passwordLessForm"
                className="flex flex-col"
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit();
                }}
                method="post"
            >
                <InputField
                    label={c('Password less input label').t`Email address`}
                    id="username"
                    type="email"
                    error={validator([requiredValidator(email), emailValidator(email)])}
                    disableChange={submitting}
                    autoComplete="username"
                    value={email}
                    onValue={setEmail}
                />
                <Button size="large" type="submit" fullWidth loading={submitting} className="mt-4">
                    <Magic />
                    {c('Password less submit button').t`Get the link`}
                </Button>
            </form>
        </>
    );
};

export default PasswordLessForm;
