import React, { useEffect, useState } from 'react';

import { c } from 'ttag';

import { Breadcrumb, getLegalDocument, useI18n } from '@freelancelabs/components';
import { LogoFreelance } from '@freelancelabs/icons';

import LanguageSwitcher from '../app/components/LanguageSwitcher';
import { PublicRoutes } from '../constants';

const CguPage = () => {
    const [cgu, setCgu] = useState({ document: '' });
    const { localeCode } = useI18n();

    const breadcrumbItems = [
        {
            name: c('Breadcrumb').t`Home`,
            to: PublicRoutes.LOGIN,
        },
        {
            name: c('Breadcrumb').t`Legal notice`,
        },
    ];

    useEffect(() => {
        const locale = localeCode.slice(0, 2).toLowerCase() as 'en' | 'fr'; // @later mv to shared i18n
        setCgu(getLegalDocument({ type: 'cgu', locale }));
    }, [localeCode]);
    return (
        <div>
            <div className="sticky top-0 border-b bg-background z-10">
                <header className="container flex justify-between items-center h-16 px-4 md:px-6 mx-auto">
                    <LogoFreelance size={64} />
                    <LanguageSwitcher />
                </header>
            </div>
            <main className="max-w-3xl mx-auto flex flex-col pb-12 px-6">
                <Breadcrumb className="mt-6 mb-12" items={breadcrumbItems} />
                <article className="flex flex-col items-center">
                    {cgu.document && (
                        <div
                            className="prose prose-sm md:prose-base max-w-full"
                            dangerouslySetInnerHTML={{ __html: cgu.document }}
                        />
                    )}
                </article>
            </main>
        </div>
    );
};

export default CguPage;
