import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { c } from 'ttag';

import { Alert, Button, useLocalState } from '@freelancelabs/components';

import PublicLayout from '../app/content/PublicLayout';
import PasswordLessForm from '../app/login/PasswordLessForm';
import { AlertType, PublicRoutes, alertMessages, defaultUsernameKey } from '../constants';

enum Steps {
    ASK,
    CONFIRM,
}

const LoginPasswordLessPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const alert: AlertType = location.state?.withAlert;

    const [username, setUsername] = useLocalState('', defaultUsernameKey);
    const [step, setStep] = useState(Steps.ASK);

    const removeAlert = () => {
        if (!location.state?.withAlert) {
            return;
        }
        delete location.state.withAlert;
        navigate('.', { replace: true, state: { ...location.state } });
    };

    const email = (
        <b key="username" className="font-semibold">
            {username}
        </b>
    );
    return (
        <PublicLayout>
            {alert && (
                <Alert
                    type="danger"
                    title={alertMessages()[alert].title}
                    message={alertMessages()[alert].message}
                    className="mb-4"
                />
            )}
            {step === Steps.ASK && (
                <>
                    <h1 className="text-2xl font-semibold text-text-norm pb-2">
                        {
                            // translator: On password less page, before email asked title
                            c('Title').t`Get a magic link`
                        }
                    </h1>
                    <span className="text-text-norm pb-6">
                        {
                            // translator: On password less page, before email asked helper
                            c('Help').t`Enter your email address to receive a temporary login link.`
                        }
                    </span>
                    <PasswordLessForm
                        defaultEmail={username}
                        onSubmit={(payload) => {
                            removeAlert();
                            setUsername(payload.email);
                            setStep(Steps.CONFIRM);
                        }}
                    />
                </>
            )}
            {step === Steps.CONFIRM && (
                <>
                    <h1 className="text-2xl font-semibold text-text-norm pb-2">
                        {
                            // translator: On password less page, after password less link asked, title
                            c('Title').t`Go to your mailbox`
                        }
                    </h1>
                    <span className="text-text-norm pb-6">
                        {
                            // translator: On password less page, after password less link asked, helper text
                            c('Help').jt`We have sent you a magic link to your email address ${email}.`
                        }
                    </span>
                    <Button onClick={() => setStep(Steps.ASK)} size="large">
                        {c('Action').t`Resend link`}
                    </Button>
                </>
            )}
            <hr className="my-6" />
            <div className="text-center text-sm font-medium">
                <Link className="link" to={PublicRoutes.LOGIN}>
                    {c('Link').t`Back to sign in`}
                </Link>
            </div>
        </PublicLayout>
    );
};
export default LoginPasswordLessPage;
