import { parse } from 'marked';

// Can't load *.md file in another repo
import cguEN from './src/cgu.en';
import cguFR from './src/cgu.fr';

export interface LegalDocument {
    document: string;
    updatedAt: Date;
    version: string;
    locale: 'fr' | 'en';
}

interface Args {
    type: 'cgu';
    locale?: 'fr' | 'en';
}

export const getLegalDocument = ({ type, locale = 'fr' }: Args): LegalDocument => {
    const documents = {
        cgu: {
            fr: {
                document: parse(cguFR()),
                updatedAt: new Date('2023-01-01'),
                version: 'v1.0.1',
                locale: 'fr',
            } as LegalDocument,
            en: {
                document: parse(cguEN()),
                updatedAt: new Date('2023-01-01'),
                version: 'v1.0.1',
                locale: 'en',
            } as LegalDocument,
        },
    };
    return documents[type][locale];
};
