import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { c } from 'ttag';

import { useConsent } from '@freelancelabs/components';
import { LogoFreelance } from '@freelancelabs/icons';

// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import asideImg from '../../assets/aside.png?h=1100&webp';
import { PublicRoutes } from '../../constants';
import LanguageSwitcher from '../components/LanguageSwitcher';

interface Props {
    children: ReactNode;
}

const PublicLayout = ({ children }: Props) => {
    const { openConsent } = useConsent();
    return (
        <div className="h-full min-w-full flex-nowrap grid md:grid-cols-2">
            <div className="flex flex-col px-4 md:px-6">
                <header className="container flex justify-end items-center h-16">
                    <LanguageSwitcher />
                </header>
                <main className="flex flex-1 md:items-center justify-center py-6">
                    <section className="md:max-w-md flex-1 flex flex-col md:rounded-2xl">
                        <div className="flex justify-center pb-6 md:pb-12">
                            <LogoFreelance size={96} />
                        </div>
                        {children}
                    </section>
                </main>
                <div className="h-16 flex items-center justify-center">
                    <Link className="link text-xs font-medium" to={PublicRoutes.CGU}>
                        {c('Link').t`Legal notice`}
                    </Link>
                    {openConsent && (
                        <span className="link text-xs font-medium cursor-pointer ml-2" onClick={openConsent}>
                            {c('Link').t`Cookies parameters`}
                        </span>
                    )}
                </div>
            </div>
            <aside
                className="hidden md:flex flex-col overflow-hidden bg-cover bg-center"
                style={{ backgroundImage: `url(${asideImg})` }}
            />
        </div>
    );
};

export default PublicLayout;
