import * as React from 'react';

import { c } from 'ttag';

import { EyeOffOutline, EyeOutline } from '@freelancelabs/icons';

import Button from '../button/Button';
import Input, { InputProps } from './Input';

type PasswordType = 'password' | 'text';

interface Props extends Omit<InputProps, 'type'> {
    defaultType?: PasswordType;
}

const PasswordInputBase = (
    { disabled, suffix, defaultType = 'password', ...rest }: Props,
    ref: React.Ref<HTMLInputElement>
) => {
    const [type, setType] = React.useState<PasswordType>(defaultType);
    const toggle = () => {
        setType(type === 'password' ? 'text' : 'password');
    };
    return (
        <Input
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            {...rest}
            ref={ref}
            type={type}
            disabled={disabled}
            suffix={
                <>
                    {suffix}
                    <Button
                        title={type === 'password' ? c('Label').t`Reveal password` : c('Label').t`Hide password`}
                        tabIndex={-1}
                        disabled={disabled}
                        onClick={toggle}
                        category="ghost"
                        size="small"
                        icon
                        type="button"
                    >
                        {type === 'password' ? <EyeOutline /> : <EyeOffOutline />}
                    </Button>
                </>
            }
        />
    );
};

const PasswordInput = React.forwardRef(PasswordInputBase);

export default PasswordInput;
