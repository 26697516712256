import * as React from 'react';
import { Link } from 'react-router-dom';

import { c } from 'ttag';

import { ChevronRight } from '@freelancelabs/icons';
import { isTruthy } from '@freelancelabs/utils';

interface Item {
    name: string;
    to?: string;
}
export interface BreadcrumbProps {
    items: Item[];
    className?: string;
}

const Breadcrumb = ({ items, className }: BreadcrumbProps) => {
    return (
        <nav className={className} aria-label={c('Breadcrumb').t`navigational aids`}>
            <ol className="breadcrumb-container">
                {items.map((item, index) => {
                    const key = index.toString();
                    const isLast = index === items.length - 1;
                    return (
                        <React.Fragment key={key}>
                            <li className="breadcrumb-item" key={key}>
                                {isTruthy(item.to) ? <Link to={item.to}>{item.name}</Link> : <span>{item.name}</span>}
                            </li>
                            {!isLast && (
                                <li aria-hidden="true">
                                    <ChevronRight size={14} className="breadcrumb-icon" />
                                </li>
                            )}
                        </React.Fragment>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
