import { fetch, getInsideApiUrl } from '@freelancelabs/shared';

import { useAuthentication } from '../hooks';

const useInsideApi = () => {
    const { getAuthorizationToken } = useAuthentication();

    return async (config: any) => {
        const { url } = config;
        const authorizationToken = await getAuthorizationToken();

        const response = await fetch({
            ...config,
            url: getInsideApiUrl(url),
            headers: {
                Authorization: `Bearer ${authorizationToken}`,
            },
        });
        const data = await response.json();
        return data;
    };
};

export default useInsideApi;
