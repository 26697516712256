import { c } from 'ttag';

export const defaultUsernameKey = 'default-username';
export const redirectAfterLoginKey = 'redirect-after-login';

export enum PublicRoutes {
    EMAIL_SENT = '/email-sent',
    LOGIN = '/login',
    LOGIN_PASSWORD_LESS = '/login/password-less',
    LOGIN_CHECK = '/login/check',
    RESET_PASSWORD = '/reset-password',
    UPDATE_PASSWORD = '/update-password',
    CGU = '/cgu',
}

export enum PrivateRoutes {
    HOMEPAGE = '/',
    REDIRECT_MIDDLEWARE = '/redirect',
}

export enum AlertType {
    LOGIN_PASSWORDLESS_EXPIRED = 'LOGIN_PASSWORDLESS_EXPIRED',
    APP_UNAUTHORIZED = 'APP_UNAUTHORIZED',
}

export const alertMessages = () => ({
    // this is a function to make ttag work
    [AlertType.LOGIN_PASSWORDLESS_EXPIRED]: {
        title: c('Alert').t`We were unable to log you into your account.`,
        message: c('Alert').t`This login link no longer works. It may have expired. Request a new one below!`,
    },
    [AlertType.APP_UNAUTHORIZED]: {
        title: c('Alert').t`You are not authorized to access this application.`,
        message: c('Alert').t`Please contact support if you think this is a mistake`,
    },
});
