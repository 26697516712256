import React from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { c } from 'ttag';

import PublicLayout from '../app/content/PublicLayout';
import { PublicRoutes } from '../constants';

const EmailSentPage = () => {
    const location = useLocation();
    const username = location.state?.username;
    if (!username) {
        return <Navigate to={PublicRoutes.LOGIN} replace />;
    }
    const email = (
        <b key="username" className="font-semibold">
            {username}
        </b>
    );
    return (
        <PublicLayout>
            <h1 className="text-2xl font-semibold text-text-norm pb-6">{c('Title').t`Reset password link sent`}</h1>
            <span className="text-sm pb-4">{c('Subtitle-1')
                .jt`We've sent you an email to reset your password to ${email}.`}</span>
            <span className="text-sm">
                {c('Subtitle-2').t`Didn't receive the email ? Check your junk email, or the spelling of your email.`}
            </span>
            <hr className="my-6" />
            <div className="text-center text-sm font-medium">
                <Link className="link" to={PublicRoutes.LOGIN}>
                    {c('Link').t`Back to sign in`}
                </Link>
            </div>
        </PublicLayout>
    );
};

export default EmailSentPage;
