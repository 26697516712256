import React, { useState } from 'react';

import { c } from 'ttag';

import { Button, ChallengeName, InputField, PasswordInput, useFormErrors, useLoading } from '@freelancelabs/components';
import { confirmPasswordValidator, passwordValidator } from '@freelancelabs/shared';
import { noop } from '@freelancelabs/utils';

import PasswordCheck from '../components/PasswordCheck';

interface Props {
    onSubmit: (newPassword: string) => Promise<void | ChallengeName>;
    /**
     * This is used by password managers to update password.
     * see https://www.chromium.org/developers/design-documents/create-amazing-password-forms/#TOC-Use-autocomplete-attributes
     */
    username?: string;
}

const SetPasswordForm = ({ onSubmit, username }: Props) => {
    const { validator, onFormSubmit } = useFormErrors();
    const [loading, withLoading] = useLoading();
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const passwordAssist = <PasswordCheck password={newPassword} />;

    return (
        <form
            name="setPasswordForm"
            className="flex flex-col"
            onSubmit={(event) => {
                event.preventDefault();
                if (loading || !onFormSubmit()) {
                    return;
                }
                withLoading(onSubmit(newPassword)).catch(noop);
            }}
            method="post"
        >
            <input
                className="hidden"
                autoComplete="username"
                type="email"
                value={username}
                id="username"
                readOnly={true}
            />
            <InputField
                label={c('Label').t`New password`}
                id="password"
                as={PasswordInput}
                error={validator([passwordValidator(newPassword)])}
                disableChange={loading}
                autoFocus={true}
                autoComplete="new-password"
                value={newPassword}
                onValue={setNewPassword}
                assist={passwordAssist}
            />
            <InputField
                label={c('Label').t`Confirm password`}
                id="password-repeat"
                as={PasswordInput}
                error={validator([
                    passwordValidator(confirmNewPassword),
                    confirmPasswordValidator(confirmNewPassword, newPassword),
                ])}
                disableChange={loading}
                autoComplete="current-password"
                value={confirmNewPassword}
                onValue={setConfirmNewPassword}
            />
            <Button size="large" type="submit" fullWidth loading={loading} className="mt-4">
                {c('Action').t`Confirm`}
            </Button>
        </form>
    );
};

export default SetPasswordForm;
