import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AuthenticationStore, LoaderPage, useAuthentication } from '@freelancelabs/components';

import { AlertType, PublicRoutes } from '../constants';

const LoginCheckPage = () => {
    const { passwordLessLoginCheck } = useAuthentication() as AuthenticationStore;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const code = searchParams.get('code');
    const email = searchParams.get('email');

    useEffect(() => {
        if (code === null || email === null) {
            navigate(PublicRoutes.LOGIN, { replace: true });
            return;
        }
        const run = async () => {
            try {
                await passwordLessLoginCheck({ code, email });
            } catch (e) {
                navigate(PublicRoutes.LOGIN_PASSWORD_LESS, {
                    replace: true,
                    state: {
                        withAlert: AlertType.LOGIN_PASSWORDLESS_EXPIRED,
                    },
                });
            }
        };

        void run();
    }, []);

    return <LoaderPage />;
};

export default LoginCheckPage;
