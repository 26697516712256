export const getUser = () => ({
    url: 'user/me',
    method: 'get',
});

export const acceptCgu = (payload: { versionName: string }) => ({
    url: 'user/terms-acceptance',
    method: 'put',
    data: { ...payload },
});
