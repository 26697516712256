import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { c } from 'ttag';

import {
    Button,
    LegalDocument,
    LoaderPage,
    getLegalDocument,
    useAuthentication,
    useI18n,
    useInsideApi,
    useLocalState,
} from '@freelancelabs/components';
import { LogoFreelance } from '@freelancelabs/icons';
import { AppName, acceptCgu, getUser, redirectToApp } from '@freelancelabs/shared';

import { AlertType, PublicRoutes, redirectAfterLoginKey } from '../../constants';

interface AppScope {
    appName: AppName;
    isConfirmed: boolean;
}

const ALLOWED_STATES = ['FORCE_CHANGE_PASSWORD', 'CONFIRMED', 'NEW_PASSWORD_REQUIRED'];

const RedirectMiddlewarePage = () => {
    const navigate = useNavigate();
    const { logout } = useAuthentication();
    const api = useInsideApi();
    const { localeCode } = useI18n();
    const [lastCgu, setLastCgu] = useState<LegalDocument>();
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [redirectAfterLogin] = useLocalState('', redirectAfterLoginKey);

    const reject = async () => {
        await logout();
        navigate(PublicRoutes.LOGIN, {
            replace: true,
            state: {
                withAlert: AlertType.APP_UNAUTHORIZED,
            },
        });
    };

    const redirect = () => {
        if (redirectAfterLogin) {
            window.location.replace(redirectAfterLogin);
            return;
        }
        redirectToApp(AppName.PORTAIL_CLIENT);
    };

    const accept = async () => {
        if (lastCgu === undefined) {
            return;
        }
        setIsLoading(true);
        await api(acceptCgu({ versionName: lastCgu.version }));
        redirect();
        setIsLoading(false);
    };

    useEffect(() => {
        const run = async () => {
            const locale = localeCode.slice(0, 2).toLowerCase() as 'en' | 'fr'; // @later mv to shared i18n
            const cgu = getLegalDocument({ type: 'cgu', locale });
            const user = await api(getUser());
            if (!ALLOWED_STATES.includes(user.state)) {
                await reject();
                return;
            }
            // Check user authorization
            // Later check access in redirect function
            // We have only one redirect app for the moment
            const portalClientScope = user.appScopes.find(
                (appScope: AppScope) => appScope.appName === AppName.PORTAIL_CLIENT
            );
            if (portalClientScope === undefined) {
                await reject();
                return;
            }
            if (!portalClientScope.isConfirmed) {
                await reject();
                return;
            }
            setLastCgu(cgu);
            if (user.termsAcceptance === cgu.version) {
                redirect();
                return;
            }
            setIsInitialized(true);
        };
        void run();
    }, []);

    return (
        <>
            {!isInitialized ? (
                <LoaderPage />
            ) : (
                <main>
                    <div className="flex justify-center p-6">
                        <LogoFreelance size={96} />
                    </div>
                    <div className="pb-12 pt-6">
                        <div className="max-w-3xl w-full mx-auto pb-6 px-6">
                            <h1 className="text-base text-text-800">
                                {c('CGU')
                                    .t`To take advantage of our online services, you must first accept their general conditions of use.`}
                            </h1>
                        </div>
                        <div className="max-w-3xl w-full mx-auto px-6 pb-6">
                            <hr />
                            <article className="flex flex-col items-center">
                                {lastCgu?.document && (
                                    <div
                                        className="prose prose-sm md:prose-base max-w-full break-words"
                                        dangerouslySetInnerHTML={{ __html: lastCgu.document }}
                                    />
                                )}
                            </article>
                        </div>
                    </div>
                    <div className="sticky bottom-0 border-t bg-background-norm">
                        <div className="max-w-3xl w-full mx-auto px-6 py-4 flex items-center gap-6 justify-between">
                            <Button onClick={logout} category="weak">
                                {c('CGU').t`Decline and exit`}
                            </Button>
                            <Button onClick={accept} loading={isLoading}>
                                {c('CGU').t`I agree`}
                            </Button>
                        </div>
                    </div>
                </main>
            )}
        </>
    );
};

export default RedirectMiddlewarePage;
