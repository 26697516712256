import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AuthenticationStore, useAuthentication } from '@freelancelabs/components';

import ProtectedRoute from '../app/components/ProtectedRoute';
import { PrivateRoutes, PublicRoutes } from '../constants';
import CguPage from './Cgu';
import EmailSentPage from './EmailSent';
import LoginPage from './Login';
import LoginCheckPage from './LoginCheck';
import LoginPasswordLessPage from './LoginPasswordLess';
import ResetPasswordPage from './ResetPassword';
import UpdatePasswordPage from './UpdatePassword';
import RedirectMiddlewarePage from './private/RedirectMiddleware';

const Pages = () => {
    const { hasSession } = useAuthentication() as AuthenticationStore;
    return (
        <BrowserRouter>
            <Routes>
                <Route path={PublicRoutes.CGU} element={<CguPage />} />
                <Route
                    element={
                        <ProtectedRoute isAllowed={!hasSession} redirectPath={PrivateRoutes.REDIRECT_MIDDLEWARE} />
                    }
                >
                    <Route path={PublicRoutes.LOGIN} element={<LoginPage />} />
                    <Route path={PublicRoutes.LOGIN_PASSWORD_LESS} element={<LoginPasswordLessPage />} />
                    <Route path={PublicRoutes.LOGIN_CHECK} element={<LoginCheckPage />} />
                    <Route path={PublicRoutes.RESET_PASSWORD} element={<ResetPasswordPage />} />
                    <Route path={PublicRoutes.UPDATE_PASSWORD} element={<UpdatePasswordPage />} />
                    <Route path={PublicRoutes.EMAIL_SENT} element={<EmailSentPage />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={hasSession} redirectPath={PublicRoutes.LOGIN} />}>
                    {/* We temporarily remove account logged page */}
                    <Route path={PrivateRoutes.HOMEPAGE} element={<RedirectMiddlewarePage />} />
                    <Route path={PrivateRoutes.REDIRECT_MIDDLEWARE} element={<RedirectMiddlewarePage />} />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Pages;
