import { useCallback, useState } from 'react';

type WithLoading = <T>(promise: Promise<T>) => Promise<T>;

const useLoading = (initialState = false): [boolean, WithLoading] => {
    const [loading, setLoading] = useState(initialState);

    const withLoading = useCallback<WithLoading>(async (promise) => {
        setLoading(true);
        try {
            const result = await promise;
            return result;
        } catch (e) {
            throw e;
        } finally {
            setLoading(false);
        }
    }, []);

    return [loading, withLoading];
};

export default useLoading;
