import * as React from 'react';

import { clsx } from '@freelancelabs/utils';

export interface DividerProps extends React.ComponentPropsWithoutRef<'hr'> {
    className?: string;
}

const Divider = ({ className, children }: DividerProps) => {
    return (
        <div className={clsx(className, 'divider')}>
            <span>{children}</span>
            <hr />
        </div>
    );
};

export default Divider;
