import React from 'react';

import { c } from 'ttag';

import { useLocalState } from '@freelancelabs/components';

import PublicLayout from '../app/content/PublicLayout';
import ResetPasswordForm from '../app/reset/ResetPasswordForm';
import { defaultUsernameKey } from '../constants';

const ResetPasswordPage = () => {
    const [username] = useLocalState('', defaultUsernameKey);
    return (
        <PublicLayout>
            <h1 className="text-2xl font-semibold text-text-norm pb-2">{c('Title').t`Reset password`}</h1>
            <span className="text-sm text-norm pb-6">
                {c('Subtitle').t`Enter your Freelance.com account email address.`}
            </span>
            <ResetPasswordForm defaultEmail={username} />
        </PublicLayout>
    );
};

export default ResetPasswordPage;
