import React from 'react';

import { Dropdown, RadioGroup, useI18n } from '@freelancelabs/components';
import { GlobeOutline } from '@freelancelabs/icons';

const LanguageSwitcher = () => {
    const { changeLocale, localeCode, availableLocales } = useI18n();

    /**
     * We don't want to display language switcher if there is not or only one available language.
     */
    const shouldDisplay = Object.keys(availableLocales).length > 1;

    const content = (
        <>
            <GlobeOutline /> <span className="truncate">{availableLocales[localeCode]}</span>
        </>
    );

    const options = Object.keys(availableLocales).map((value) => ({
        value,
        label: availableLocales[value],
    }));

    return (
        <>
            {shouldDisplay && (
                <Dropdown content={content} buttonCategory="weak">
                    <RadioGroup
                        className="px-2"
                        category="check"
                        value={localeCode}
                        options={options}
                        onValue={changeLocale}
                    />
                </Dropdown>
            )}
        </>
    );
};

export default LanguageSwitcher;
