import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { c } from 'ttag';

import { AuthenticationStore, useAuthentication, useNotifications } from '@freelancelabs/components';

import PublicLayout from '../app/content/PublicLayout';
import SetPasswordForm from '../app/login/SetPasswordForm';
import { PublicRoutes } from '../constants';

const UpdatePasswordPage = () => {
    const [searchParams] = useSearchParams();
    const { forgotPasswordSubmit, login } = useAuthentication() as AuthenticationStore;
    const { createNotification } = useNotifications();

    const code = searchParams.get('code');
    const email = searchParams.get('email');
    if (!code || !email) {
        return <Navigate to={PublicRoutes.LOGIN} replace />;
    }
    return (
        <PublicLayout>
            <h1 className="text-xl font-semibold text-text-norm">{c('Title').t`Change your password`}</h1>
            <span className="text-sm text-text-hint pb-6">
                {c('Subtitle').t`Enter your new password below for your Freelance.com account.`}
            </span>
            <SetPasswordForm
                username={email}
                onSubmit={async (newPassword) => {
                    try {
                        await forgotPasswordSubmit({ email, code, newPassword });
                        return await login({ email, password: newPassword });
                    } catch (e) {
                        createNotification({
                            type: 'danger',
                            text: c('Update Password Error').t`Something went wrong, please try again.`,
                        });
                        throw e;
                    }
                }}
            />
        </PublicLayout>
    );
};

export default UpdatePasswordPage;
