import * as React from 'react';
import { Ref, SVGProps, forwardRef } from 'react';

type IconSize = 6 | 12 | 14 | 16 | 20 | 24 | 48 | 64 | 96 | 126;
interface Props extends SVGProps<SVGSVGElement> {
    /** If specified, renders an inline title element */
    title?: string;
    /** The size of the icon */
    size?: IconSize;
    /** How many degrees the icon should be rotated */
    rotate?: number;
    className?: string;
    alt?: string;
}
const SvgMagic = ({ size = 16, rotate, title, className, ...props }: Props, ref: Ref<SVGSVGElement>) => {
    const titleId = title;
    const style = {
        height: size.toString(10).concat('px'),
        width: size.toString(10).concat('px'),
        ...(rotate && {
            transform: 'rotate('.concat(rotate.toString(10)).concat('deg)'),
        }),
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            focusable="false"
            role="img"
            style={style}
            className={className}
            ref={ref}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M15.199 9.945a2.6 2.6 0 0 1-.79-1.551l-.403-3.083-2.73 1.486a2.6 2.6 0 0 1-1.72.273L6.5 6.5l.57 3.056a2.6 2.6 0 0 1-.273 1.72l-1.486 2.73 3.083.403a2.6 2.6 0 0 1 1.55.79l2.138 2.257 1.336-2.807a2.6 2.6 0 0 1 1.23-1.231l2.808-1.336-2.257-2.137zm.025 5.563l-2.213 4.65a.6.6 0 0 1-.977.155l-3.542-3.739a.6.6 0 0 0-.357-.182l-5.107-.668a.6.6 0 0 1-.449-.881l2.462-4.524a.6.6 0 0 0 .062-.396L4.16 4.86a.6.6 0 0 1 .7-.7l5.063.943a.6.6 0 0 0 .396-.062l4.524-2.462a.6.6 0 0 1 .881.45l.668 5.106a.6.6 0 0 0 .182.357l3.739 3.542a.6.6 0 0 1-.155.977l-4.65 2.213a.6.6 0 0 0-.284.284zm.797 1.927l1.414-1.414 4.243 4.242-1.415 1.415-4.242-4.243z" />
        </svg>
    );
};
const ForwardRef = forwardRef(SvgMagic);
export default ForwardRef;
