import * as React from 'react';

import { Check } from '@freelancelabs/icons';
import { clsx } from '@freelancelabs/utils';

import { generateUID } from '../../../helpers';
import { useInstance } from '../../../hooks';

export type RadioSize = 'small' | 'medium';

interface RadioGroupOptionsProps<T> {
    value: T;
    label: string;
    disabled?: boolean;
}

export interface RadioGroupProps<T> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    options: RadioGroupOptionsProps<T>[];
    onValue?: (arg0: T) => void;
    children?: React.ReactNode;
    category?: 'default' | 'check';
    size?: RadioSize;
}

const RadioBase = <T extends React.InputHTMLAttributes<T>['value']>({
    id,
    options,
    value,
    onValue,
    children,
    disabled,
    className,
    onChange,
    size = 'medium',
    category = 'default',
    ...rest
}: RadioGroupProps<T>) => {
    const prefixId = useInstance(() => generateUID('radio'));
    return (
        <div className={clsx('radio-group', className)}>
            {options.map((option) => {
                const id = `${prefixId}-${option.value?.toString()}`;
                const checked = value === option.value;
                return (
                    <label
                        key={id}
                        htmlFor={id}
                        className={clsx(
                            'radio-label',
                            `radio-${category}-label`,
                            option.disabled && 'is-disabled',
                            checked && 'is-checked',
                            `radio-${size}`
                        )}
                    >
                        <input
                            id={id}
                            value={option.value}
                            onChange={(e) => {
                                onChange?.(e);
                                onValue?.(e.target.value as T);
                            }}
                            checked={checked}
                            type="radio"
                            className={clsx('radio', `radio-${category}`, checked && 'is-checked')}
                            disabled={option.disabled}
                            {...rest}
                        />
                        {category === 'default' && (
                            <span className={clsx('radio-default-fakeradio', children ? '' : 'no-children')} />
                        )}
                        {children || option.label}
                        {category === 'check' && (
                            <>
                                <div className="radio-check-space" />
                                <div className="radio-check-fakecheck">
                                    <Check className={clsx('radio-check-fakecheck-icon', checked && 'is-checked')} />
                                </div>
                            </>
                        )}
                    </label>
                );
            })}
        </div>
    );
};

export default RadioBase;
